import styled from 'styled-components'
import {pxToRem} from '../../utils/style'
import {n44} from '../../utils/numbers'

export const MenuButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${props => props.theme.spacing(2)};
  cursor: pointer;
  width: ${pxToRem(n44)};
  height: ${pxToRem(n44)};
  border-radius: 50%;
`

export const MenuTitle = styled.span`
  text-transform: uppercase;
`