import styled from 'styled-components'
import {BackArrowIcon} from '../../Icons/Icons'
import {pxToRem} from '../../../../utils/style'
import {n48} from '../../../../utils/numbers'

export const BackArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding-bottom: ${props => props.theme.spacing(2)};
`

export const StyledBackArrowIcon = styled(BackArrowIcon)`
  width: ${pxToRem(n48)};
  height: ${pxToRem(n48)};
`;

BackArrowContainer.displayName = 'BackArrowContainer'