import { Dispatch, SetStateAction } from 'react'
import { TypeWrapper, Wrapper } from './PlanogramSwitch.style'
import { Type } from '../../../types/clusters'
import { useTranslation } from 'react-i18next'

type Props = {
  setSelectedType: Dispatch<SetStateAction<Type | null>>
  selectedType: Type | null
}

const PlanogramSwitch: React.FC<Props> = ({
  selectedType,
  setSelectedType,
}) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <TypeWrapper
        onClick={() => setSelectedType('sun' as Type)}
        selected={selectedType === 'sun'}
      >
        {t('productType.sun')}
      </TypeWrapper>
      <TypeWrapper
        onClick={() => setSelectedType(null)}
        selected={!selectedType}
      >
        {t('productType.all')}
      </TypeWrapper>
      <TypeWrapper
        onClick={() => setSelectedType('opt' as Type)}
        selected={selectedType === 'opt'}
      >
        {t('productType.opt')}
      </TypeWrapper>
    </Wrapper>
  )
}

export default PlanogramSwitch
