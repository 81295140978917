import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { BrandAndCluster } from "../../types/brands";
import { BrandClusterPayload } from '../../types/clusters'
import { Cluster } from "./../../types/clusters";
import { MAX_CLUSTER_TO_COMPARE } from "../../components/CompareCounter/CompareCounter";

type StateInterface = {
  current: BrandClusterPayload | null
  selected: Cluster[]
  compare: BrandAndCluster[]
  compareWarning: boolean
  resetSidebarView: boolean
  switchSelection: 'sun' | 'opt'
}

const initialState: StateInterface = {
  current: null,
  selected: [],
  compare: [],
  compareWarning: false,
  resetSidebarView: false,
  switchSelection: 'sun'
}

export const ClustersSlice = createSlice({
  name: "clusters",
  initialState,

  reducers: {
    setCurrentCluster: (state, action: PayloadAction<BrandClusterPayload>) => {
      state.current = action.payload;
    },
    setSwitchSelection: (state, {payload}: PayloadAction<'sun' | 'opt'>) => {
      state.switchSelection = payload
    },
    toggleClusterSelection: (state, action: PayloadAction<Cluster>) => {
      const cluster = action.payload;
      const index = state.selected.findIndex(
        (b) => b.code === cluster.code && b.month === cluster.month && b.brandCode === cluster.brandCode,
      );

      if (index >= 0) {
        state.selected.splice(index, 1);
      } else {
        state.selected.push(cluster);
      }
    },
    resetSelectedClusters: (state) => {
      state.selected = [];
    },
    toggleClusterCompare: (
      state,
      { payload }: PayloadAction<BrandAndCluster>,
    ) => {
      const { brand: currentBrand, cluster } = payload;

      if (!state.compare.length) {
        state.compare.push(payload);
        return;
      }

      const currentClusterKey = `${cluster.code}-${cluster.month}-${cluster.brandCode}`;
      const clusterIndex = state.compare.findIndex(
        ({ cluster }) =>
          `${cluster.code}-${cluster.month}-${cluster.brandCode}` === currentClusterKey,
      );
      const clusterIsAlreadyCompared = clusterIndex >= 0;

      if (clusterIsAlreadyCompared) {
        state.compare.splice(clusterIndex, 1);
        return;
      }

      const maxNumberOfCompareIsReached =
        state.compare.length === MAX_CLUSTER_TO_COMPARE;
      const clusterIsNotComparable = state.compare.some(
        ({ brand }) =>
          brand.group.toLowerCase() !== currentBrand.group.toLowerCase(),
      );

      if (maxNumberOfCompareIsReached || clusterIsNotComparable) {
        state.compareWarning = true;
      } else {
        state.compare.push(payload);
      }
    },
    resetCompareClusters: (state) => {
      state.compare = [];
    },
    setClustersFromReview: (state, { payload }: PayloadAction<Cluster[]>) => {
      state.selected = payload;
    },
    setCompareWarning: (state, { payload }: PayloadAction<boolean>) => {
      state.compareWarning = payload;
    },
    setResetSidebarView: (state, { payload }: PayloadAction<boolean>) => {
      state.resetSidebarView = payload;
    },
    setResetCompare: (state) => {
      state.compare = [];
    },
  },
});

export const {
  setCurrentCluster,
  toggleClusterSelection,
  toggleClusterCompare,
  setCompareWarning,
  setClustersFromReview,
  setResetSidebarView,
  resetSelectedClusters,
  resetCompareClusters,
  setSwitchSelection,
  setResetCompare,
} = ClustersSlice.actions;

export default ClustersSlice.reducer;
