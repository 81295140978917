import styled from 'styled-components'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import ToggleButton from '@mui/material/ToggleButton'
import {pxToRem} from '../../../utils/style'
import {n40, n240} from '../../../utils/numbers'


export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  border: 2px solid ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.color.white.main};
  padding: ${(props) => props.theme.spacing(.5)};
  border-radius: 10px;
  max-width: ${pxToRem(n240)};
  height: ${pxToRem(n40)};
  & .Mui-selected, & .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
    background-color: ${(props) => props.theme.palette.primary.light};
  };
  text-transform: uppercase;
`;

export const StyledToggleButton = styled(ToggleButton)`
  color: ${props => props.theme.palette.color.white.main};
  background-color: ${(props) => props.theme.palette.primary.main} !important;
  font-weight: bolder;
  &.Mui-selected {
    background-color: ${props => props.theme.palette.primary.light} !important;
    color: ${props => props.theme.palette.color.white.main} !important;
    border-radius: 10px !important; // Aggiungi il border-radius qui
  }

  &:not(:first-of-type) {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  &:not(:last-of-type) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &.Mui-selected:not(:first-of-type) {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  &.Mui-selected:not(:last-of-type) {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }
`;
