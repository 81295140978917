import styled from 'styled-components'
import {Z_INDEX_LV5} from '../../constants/ui'

export const SelectionRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spacing(8)};
`

export const BrandCategorySelectorRow = styled.div`
  display: flex;
  align-items: center;
  z-index: ${Z_INDEX_LV5};
  gap: ${props => props.theme.spacing(4)};
`

export const BrandCategorySelector = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

export const StyledExploreSpan = styled.span`
  display: flex;
  gap: ${props => props.theme.spacing(2)};
`

export const StyledCategorySpan = styled('span')
    .withConfig({
        shouldForwardProp: (prop) => prop !== 'isSelectedBrandCategory'
    })<{ isSelectedBrandCategory: boolean }>(({isSelectedBrandCategory, theme}) => ({
        color: isSelectedBrandCategory ? theme.palette.primary.light : theme.palette.color.white.main,
        fontWeight: isSelectedBrandCategory ? 'normal' : 'bolder',
    }))

export const OtherBrandsCategory = styled.span`
  text-transform: capitalize;
  color: white;
  font-weight: bolder;
`

export const BrandSelectCTAContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.color.white.main};
`

export const ArrowContainer = styled('div')
    .withConfig({
        shouldForwardProp: (prop) => prop !== 'isSelectedBrandCategory'
    })<{ isSelectedBrandCategory: boolean }>(({isSelectedBrandCategory, theme}) => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `2px solid ${theme.palette.primary.light}`,
        backgroundColor: isSelectedBrandCategory ? theme.palette.primary.light : 'none',
        borderRadius: 12,
        cursor: 'pointer',
    }))