import styled from 'styled-components'

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  color: ${(props) => props.theme.palette.color.white.main};
  background: ${(props) => props.theme.palette.color.backgrounds.main};
`
