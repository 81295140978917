import { FetchArgs, fetchBaseQuery, retry } from '@reduxjs/toolkit/query'

import { getSessionToken } from './persistance'
import { n401 } from './numbers'
import { parseJwt } from './utils'

export const staggeredBaseQuery = (baseUrl: string, bypassBearer = false) =>
  retry(
    async (args: string | FetchArgs, api, extraOptions) => {
      const token = getSessionToken()
      const loginUrl = `${process.env.REACT_APP_LOGIN_URL}`
      const OCP_APIM_SUBSCRIPTION_KEY = `${process.env.REACT_APP_SUBSCRIPTION_KEY}`
      const result = await fetchBaseQuery({
        baseUrl,
        prepareHeaders: (headers) => {
          headers.append('Content-Type', 'application/json')
          headers.append('Ocp-Apim-Subscription-Key', OCP_APIM_SUBSCRIPTION_KEY)

          if (!bypassBearer) {
            headers.set('authorizationjwt', `Bearer ${token}`)
          }
        },
      })(args, api, extraOptions)
      
      if (result.error && result.error.status === n401 && !bypassBearer) {
        window.location.href = loginUrl
      }

      // retry only if the error is on the server
      // 401 errors are handled above
      if (
        

        result.error &&
        result.error.status !== n401
        // result.error.status > 304 &&
        // result.error.status < 500
      ) {
        retry.fail(result.error)
      }

      return result
    },
    {
      maxRetries: 3,
    }
  )

export const downloadPlanogram = async (): Promise<boolean> => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/download`
  const token = getSessionToken()
  const jwtInfo = parseJwt(token)
  const email = jwtInfo.emails[0]
  const signInName = jwtInfo.signInName

  const requestBody = {
    email,
    signInName,
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        authorizationjwt: `Bearer ${token}`,
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_SUBSCRIPTION_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })

    if (!response.ok) {
      console.error(`HTTP error! Status: ${response.status}`)
      return false
    }

    const blobData = await response.blob()

    if (!blobData) {
      console.error('Error in parsing blob')
      return false
    }

    const blobUrl = window.URL.createObjectURL(blobData)

    if (!blobUrl) {
      console.error('Error in creating object URL')
      return false
    }

    const link = document.createElement('a')
    link.href = blobUrl
    link.download = 'Planogram.xlsx'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)

    return true
  } catch (error) {
    console.error('Fetch error:', error)
    return false
  }
}
