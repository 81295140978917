import AppHeader from '../../components/AppHeader/AppHeader'
import { AppState } from '../../redux/store'
import Modal from '../../components/Modal/Modal'
import Pdp from './Pdp/Pdp'
import { PlpContainer } from './Plp.style'
import PlpProductCard from './PlpProductCard/PlpProductCard'
import { useGetClusterUnique } from '../../api/BrandsApi'
import { useSelector } from 'react-redux'
import { useState } from 'react'

type SkuCode = string

const Plp: React.FC = () => {
  const currentCluster = useSelector((state: AppState) => state.cluster.current)

  if (!currentCluster) {
    throw new Error('Cluster not found')
  }

  const [selectedSkuCode, setSelectedSkuCode] = useState<SkuCode | null>(null)

  const selectedCluster = useGetClusterUnique(currentCluster)
  const clusterProducts = selectedCluster && selectedCluster.data?.products
  const openedProduct = clusterProducts?.find(
    (product) => product.skuCode === selectedSkuCode
  )

  return (
    <>
      <AppHeader plpCluster={selectedCluster.data} />
      <PlpContainer>
        {clusterProducts &&
          clusterProducts.map((product, index) => (
            <PlpProductCard
              setSelectedSkuCode={setSelectedSkuCode}
              key={`${product.skuCode}-${index}`}
              product={product}
            />
          ))}
      </PlpContainer>
      <Modal
        onClose={() => setSelectedSkuCode(null)}
        visible={!!selectedSkuCode}
      >
        <Pdp product={openedProduct} />
      </Modal>
    </>
  )
}

export default Plp
