import { Token } from '../types/user'

const TOKENS_KEY = 'token'

export const setSessionToken = (token: Token) => {
    window.sessionStorage.setItem(TOKENS_KEY, token)
}

export const getSessionToken = (): Token => {
    return window.sessionStorage.getItem(TOKENS_KEY) || ''
}

export const delSessionToken = () => {
    window.sessionStorage.removeItem(TOKENS_KEY)
}
