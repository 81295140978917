import React, {ReactNode} from 'react'
import {Backdrop, DialogWrapper} from './Dialog.style'

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    top?: number
    right?: number
    bottom?: number
    left?: number
}

const Dialog: React.FC<DialogProps> = ({ isOpen, onClose, children, top, right, bottom, left }) => {
    if (!isOpen) return null;

    return (
        <>
            <Backdrop onClick={onClose} />
            <DialogWrapper top={top} right={right} bottom={bottom} left={left}>
                {children}
            </DialogWrapper>
        </>
    );
};

export default Dialog;