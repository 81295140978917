import { Action, compose } from 'redux'
import { PayloadAction, configureStore } from '@reduxjs/toolkit'

import {BrandsApi} from '../../api/BrandsApi'
import {CmsApi} from '../../api/CmsApi'
import {loadState} from '../../localStorage'
import rootReducer from '../reducers/index'

const persistedState = loadState()
const devTools =
    (process.env.NODE_ENV !== 'production' &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25,
        })) ||
    compose


const store = configureStore({
    reducer: rootReducer,
    devTools,
    preloadedState: persistedState,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat([
            BrandsApi.middleware,
            CmsApi.middleware,
        ]),
})

export type AppStore = typeof store
export type AppState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch

type ExtraProps = {}

export type AppPayloadWithWidget<T = ExtraProps> = { widget: string } & T
export type AppPayloadAction<
    P = ExtraProps,
    T extends string = string
> = PayloadAction<P, T>
export type AppResponseAction<R = ExtraProps, T extends string = string> = Action<T> & {
    response: R
}
export type AppPayloadWithWidgetAction<
    P = ExtraProps,
    T extends string = string
> = AppPayloadAction<AppPayloadWithWidget<P>, T>


export type RootState = ReturnType<typeof store.getState>
export default store
