import * as ROUTES from '../constants/routes'

import { Route, Routes } from 'react-router-dom'

import Clusters from '../pages/Clusters/Clusters'
import Home from '../pages/Home/Home'
import Plp from '../pages/Plp/Plp'
import React from 'react'
import { UserControl } from '../components/UserControl'

export const AppRoutes = {
  login: (): string => '/',
  intro: (): string => '/intro',
  logout: (): string => '/logout',
  create: (): string => '/create',
  edit: (): string => '/edit',
  load: (): string => '/load',
  editStyle: (): string => '/edit/style',
  editCatalog: (): string => '/edit/catalog',
  editProducts: (): string => '/edit/products',
  editConfirmation: (): string => '/edit/confirmation',
  thankYou: (): string => '/edit/thank-you',
  groupList: (): string => '/groups',
  userList: (): string => '/users',
  groupPage: (): string => '/groups/:id',
}

export const CreateRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UserControl />} />
      <Route path={ROUTES.HOME} element={
        <Home />
      } />
      <Route path={ROUTES.CLUSTERS} element={
        <Clusters />
      } />
      <Route path={ROUTES.PLP} element={
        <Plp />
      } />
    </Routes>
  )
}

