import * as React from 'react'
import {StyledToggleButton, StyledToggleButtonGroup} from './Toggle.style'

type ToggleButtonProps = {
  selType: string
  onTypeChange: (newTypeSelection: 'opt' | 'sun') => void
  typesOptions: { value: string; label: string }[]
  disabled: boolean
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  selType,
  onTypeChange,
  typesOptions,
  disabled,
}) => {
  return (
    <StyledToggleButtonGroup
      disabled={disabled}
      value={selType}
      exclusive
      onChange={(
        _: React.MouseEvent<HTMLElement>,
        newTypeSelection: 'opt' | 'sun',
      ) => {
        onTypeChange(newTypeSelection)
      }}
      aria-label="Types"
    >
      {typesOptions.map((option) => (
        <StyledToggleButton
          key={option.value}
          value={option.value}
          selected={selType === option.value}
        >
          {option.label}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}

export default ToggleButton;
