import styled from 'styled-components'

import {pxToRem} from '../../utils/style'
import {n16, n48} from '../../utils/numbers'

const shouldForwardProp = (prop: PropertyKey) => !['disabled'].includes(prop as string)

export const ConfirmSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
  margin-bottom: ${props => props.theme.spacing(38)};
`
export const ConfirmCTA = styled('button').withConfig({
  shouldForwardProp
})`
  width: auto;
  height: ${pxToRem(n48)};
  display: flex;
  align-items: center;
  color: ${(props) => props.disabled ? props.theme.palette.color.grey.light : props.theme.palette.color.white.main};
  background-color: ${(props) => props.theme.palette.primary.light};
  border: none;
  font-size: ${pxToRem(n16)};
  font-weight: bold;
  text-transform: uppercase;
  cursor: ${(props) => props.disabled ? 'initial' : 'pointer'};
  opacity: ${(props) => props.disabled ? '0.9' : '1'};
  padding: ${(props) => props.theme.spacing(4)};
  border-radius: 10px;
`
