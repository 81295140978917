import type { CustomSVGIconProps } from './icon.type'
import IconWrapper from './IconWrapper'
import React from 'react'

export const BackArrowIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M36.8003 36.0654C36.3687 36.0654 36.0615 35.7666 36.0615 35.3267C36.0615 35.0361 36.0781 34.7622 36.0781 34.3721C36.0781 31.2925 35.0073 30.0391 31.9609 30.0391H26.2334L24.1914 29.9146L26.9971 32.4712L28.8398 34.3472C28.9727 34.48 29.0391 34.6792 29.0391 34.8784C29.0391 35.2935 28.7236 35.5923 28.3169 35.5923C28.1177 35.5923 27.9434 35.5176 27.769 35.3599L22.2573 29.8564C22.0913 29.6904 22 29.4995 22 29.292C22 29.0928 22.0913 28.8936 22.2573 28.7358L27.7524 23.249C27.9351 23.0664 28.1177 23 28.3169 23C28.7236 23 29.0391 23.2988 29.0391 23.7139C29.0391 23.9131 28.9727 24.104 28.8398 24.2368L26.9971 26.1128L24.1997 28.6694L26.2334 28.5532H32.0356C35.9785 28.5532 37.5806 30.2798 37.5806 34.2974C37.5806 34.7788 37.564 35.1938 37.5142 35.4429C37.4395 35.7832 37.2402 36.0654 36.8003 36.0654Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const CheckedIcon: React.FC<CustomSVGIconProps> = ({
  width = 48,
  height = 48,
  htmlColor,
  ...rest
}) => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path d="M0 0h24v24H0V0zm0 0h24v24H0V0z" fill={'none'} />
      <path
        d="M16.59 7.58L10 14.17l-3.59-3.58L5 12l5 5 8-8zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const OpticaGlassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 16,
  height = 16,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M15.86 6.841c-.305-1.162-1.08-1.92-2.131-2.078a11.184 11.184 0 0 0-1.396-.094v-.002H3.666v.002c-.484.003-.964.037-1.402.095C1.22 4.922.444 5.679.14 6.842c-.393 1.498.08 3.228 1.099 4.024.396.31.875.466 1.398.466.312 0 .64-.056.975-.168 1.532-.514 2.992-2.113 3.58-3.884a1.367 1.367 0 0 1 1.615 0c.584 1.766 2.046 3.369 3.58 3.884.335.112.663.168.975.168.523 0 1.002-.157 1.398-.466 1.02-.796 1.492-2.526 1.1-4.025zM6.645 6.79c-.451 1.689-1.816 3.263-3.245 3.743-.48.161-1.173.258-1.75-.191C.84 9.709.46 8.247.784 7.01c.138-.525.526-1.429 1.573-1.587.451-.06.892-.089 1.309-.091h.138c1.244.012 2.26.27 2.668.722.186.207.24.443.172.734zm.687-.337a1.387 1.387 0 0 0-.365-.844 1.863 1.863 0 0 0-.32-.274h2.706a1.86 1.86 0 0 0-.32.274c-.22.243-.341.529-.365.844A1.99 1.99 0 0 0 8 6.334c-.23 0-.455.042-.668.118zm7.018 3.889c-.577.45-1.27.352-1.75.191-1.43-.48-2.794-2.054-3.243-3.733-.07-.301-.017-.537.17-.744.41-.452 1.426-.71 2.67-.721h.136c.416.002.854.03 1.302.089 1.054.16 1.442 1.063 1.58 1.588.324 1.235-.056 2.698-.865 3.33z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const RightArrowIcon: React.FC<CustomSVGIconProps> = ({
  width = 18,
  height = 18,
  htmlColor,
  ...rest
}) => {
  return (
    // eslint-disable-next-line react/jsx-no-undef
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M17.3057 7C17.3057 7.26367 17.1914 7.51855 16.9893 7.71191L11.1885 13.5039C10.9775 13.7061 10.749 13.8027 10.5029 13.8027C9.95801 13.8027 9.5625 13.4072 9.5625 12.8799C9.5625 12.6074 9.66797 12.3701 9.84375 12.2031L11.8125 10.208L14.4053 7.83496L12.3574 7.95801H1.66992C1.08984 7.95801 0.694336 7.5625 0.694336 7C0.694336 6.42871 1.08984 6.04199 1.66992 6.04199H12.3574L14.3965 6.16504L11.8125 3.79199L9.84375 1.79688C9.66797 1.62109 9.5625 1.38379 9.5625 1.11133C9.5625 0.583984 9.95801 0.197266 10.5029 0.197266C10.749 0.197266 10.9863 0.285156 11.2061 0.504883L16.9893 6.28809C17.1914 6.47266 17.3057 6.73633 17.3057 7Z"
        fill={htmlColor}
      />
    </IconWrapper>
  )
}

export const SunglassesIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 52,
  height = 52,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path
        d="M47.581 20.523c-.915-3.487-3.238-5.759-6.393-6.235a33.355 33.355 0 0 0-4.187-.281V14h-26v.007c-1.453.006-2.892.11-4.206.284-3.136.474-5.459 2.746-6.374 6.233-1.179 4.495.24 9.686 3.297 12.073 1.19.928 2.626 1.398 4.196 1.398.936 0 1.918-.167 2.924-.505 4.595-1.542 8.975-6.338 10.74-11.652 1.394-1.056 3.448-1.057 4.844-.001 1.752 5.3 6.14 10.109 10.741 11.653 1.006.337 1.988.505 2.924.505 1.57 0 3.007-.471 4.196-1.398 3.06-2.388 4.476-7.578 3.298-12.074zm-25.584-1.169a4.163 4.163 0 0 0-1.095-2.53 5.59 5.59 0 0 0-.962-.823h8.121a5.628 5.628 0 0 0-.962.823 4.161 4.161 0 0 0-1.092 2.531A5.97 5.97 0 0 0 24.001 19c-.69 0-1.365.127-2.004.354z"
        fill={htmlColor}
        stroke="none"
      />
    </IconWrapper>
  )
}

export const CircleIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 38,
  height = 38,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill={htmlColor}
      {...rest}
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={htmlColor}
        strokeWidth="1"
        fill="none"
      />
      <line x1="8" y1="8" x2="16" y2="16" stroke="black" strokeWidth="1" />
      <line x1="16" y1="8" x2="8" y2="16" stroke="black" strokeWidth="1" />
    </IconWrapper>
  )
}

export const CloseIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 38,
  height = 38,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <path d="m 23.0375,19 14.725,14.725 C 37.920833,33.883333 38,34.101042 38,34.378125 c 0,0.277083 -0.07917,0.494792 -0.2375,0.653125 L 35.03125,37.7625 C 34.872917,37.920833 34.655208,38 34.378125,38 34.101042,38 33.883333,37.920833 33.725,37.7625 L 19,23.0375 4.275,37.7625 C 4.1166673,37.920833 3.8989577,38 3.621875,38 3.3447923,38 3.1270827,37.920833 2.96875,37.7625 L 0.2375,35.03125 C 0.07916727,34.872917 0,34.655208 0,34.378125 0,34.101042 0.07916727,33.883333 0.2375,33.725 L 14.9625,19 0.2375,4.275 C 0.07916727,4.1166673 0,3.8989577 0,3.621875 0,3.3447923 0.07916727,3.1270827 0.2375,2.96875 L 2.96875,0.2375 C 3.1270827,0.07916727 3.3447923,0 3.621875,0 3.8989577,0 4.1166673,0.07916727 4.275,0.2375 L 19,14.9625 33.725,0.2375 C 33.883333,0.07916727 34.101042,0 34.378125,0 34.655208,0 34.872917,0.07916727 35.03125,0.2375 L 37.7625,2.96875 C 37.920833,3.1270827 38,3.3447923 38,3.621875 38,3.8989577 37.920833,4.1166673 37.7625,4.275 Z" />
    </IconWrapper>
  )
}

export const DownloadIcon: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 38,
  height = 38,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      fill="none"
      {...rest}
    >
      <g>
        <path
          d="M13.569 8.23567L13.0977 7.76433L8.33333 12.5287V0H7.66667V12.5287L2.90233 7.76433L2.431 8.23567L8 13.8047L13.569 8.23567Z"
          fill="white"
        />
        <path
          d="M15.3333 11.6667V13.3333C15.3333 14.4363 14.4363 15.3333 13.3333 15.3333H2.66667C1.56367 15.3333 0.666667 14.4363 0.666667 13.3333V11.6667H0V13.3333C0 14.8037 1.19633 16 2.66667 16H13.3333C14.8037 16 16 14.8037 16 13.3333V11.6667H15.3333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath>
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </IconWrapper>
  )
}

export const ChevronRight: React.FC<CustomSVGIconProps> = ({
  htmlColor,
  width = 52,
  height = 52,
  ...rest
}) => {
  return (
    <IconWrapper
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      height={height}
      {...rest}
    >
      <path d="M9.45355 7.99548C9.45355 7.76777 9.36247 7.54014 9.18939 7.38525L1.96716 0.336045C1.81233 0.181232 1.61196 0.0901489 1.38428 0.0901489C0.910686 0.0901489 0.546386 0.445394 0.546386 0.918926C0.546386 1.14664 0.637463 1.35609 0.783183 1.51098L7.91436 8.48729L7.91436 7.51278L0.783184 14.4891C0.637464 14.6349 0.546387 14.8443 0.546387 15.0811C0.546387 15.5547 0.910687 15.9099 1.38428 15.9099C1.61196 15.9099 1.81233 15.8188 1.97627 15.664L9.18939 8.6148C9.36247 8.44175 9.45355 8.23228 9.45355 7.99548Z" />
    </IconWrapper>
  )
}
