import {
  ClusterName,
  ImageWrapper,
  ImagesWrapper,
  InfoWrapper,
  LogoWrapper,
  Wrapper,
} from './CompareCard.style'

import { BrandAndCluster } from '../../types/brands'
import { ButtonType } from '../../types/common'
import { ProductNoImagePlaceholder } from '../Planogram/PlanogramCard/PlanogramCard.style'
import SelectButton from '../UI/Buttons/SelectButton/SelectButton'
import { css } from 'styled-components'

type Props = {
  brandAndCluster: BrandAndCluster
}

const CompareCard: React.FC<Props> = ({ brandAndCluster }) => {
  const { brand, cluster } = brandAndCluster
  const clusterKey = cluster.code + '-' + cluster.month

  const handleSelectButtonClick = () => {}

  return (
    <Wrapper>
      <LogoWrapper>
        {brand.urlBrandLogo ? (
          <img src={brand.urlBrandLogo + '?imwidth=300'} alt="brand-logo" />
        ) : (
          <span>{brand.brandName}</span>
        )}
      </LogoWrapper>
      <ImagesWrapper>
        {cluster.products.map(({ imageUrl }, index) => {
          return (
            <ImageWrapper key={`${imageUrl}-${index}`}>
              {imageUrl ? (
                <img src={imageUrl + '?imwidth=300'} alt="product" />
              ) : (
                <ProductNoImagePlaceholder />
              )}
            </ImageWrapper>
          )
        })}
      </ImagesWrapper>
      <InfoWrapper>
        <ClusterName>{cluster.code}</ClusterName>

        <SelectButton
          onClick={handleSelectButtonClick}
          type={ButtonType.CLUSTER}
          key={clusterKey}
          cluster={cluster}
          brandGroup={brand.group}
          customStyle={css`
            box-shadow: none;
          `}
        />
      </InfoWrapper>
    </Wrapper>
  )
}

export default CompareCard
