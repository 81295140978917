import {
  BadgeContainer,
  CardHeaderImage,
  ClusterCardContainer,
  ClusterCardDescription,
  ClusterCardPLPContainer,
  ClusterCode,
  ClusterCodeContainer,
  ClusterCodeIconContainer,
  ClusterImageContainer,
  GlassesIconContainer,
  GradientOverlay,
  PlpButtonsContainer,
  ProductImageContainer,
  ProductImageIcon,
  ProductImagesGrid,
  ProductImagesGridContainer,
  ProductNoImagePlaceholder,
  SmallSunglassesIcon,
  StyledButton,
} from './ClusterCard.style'
import {
  CheckedIcon,
  IconWrapper,
} from '../UI/Buttons/SelectButton/SelectButton.style'
import {
  setCurrentCluster,
  toggleClusterCompare,
} from '../../redux/slices/ClusterSlice'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../redux/store'
import Badge from '../UI/Badge/Badge'
import { ButtonType } from '../../types/common'
import { Cluster } from '../../types/clusters'
import { PLP } from '../../constants/routes'
import React from 'react'
import { RightArrowIcon } from '../UI/Icons/Icons'
import SelectButton from '../UI/Buttons/SelectButton/SelectButton'
import { useGetClusterUnique } from '../../api/BrandsApi'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface ClusterCardProps {
  cluster: Cluster
}

const ClusterCard: React.FC<ClusterCardProps> = ({ cluster }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const brand = useSelector((state: AppState) => state.brands.current)

  const compareClusters = useSelector(
    (state: AppState) => state.cluster.compare,
  )
  const clusterKey = cluster.code + '-' + cluster.month + '-' + cluster.brandCode 
  const isCompareSelected = compareClusters.some(
    ({ cluster, brand }) => clusterKey === `${cluster.code}-${cluster.month}-${brand.brandCode}`,
  )

  const products = cluster.products

  const productListColumns = Math.ceil(products?.length / 3)

  const { data: currentCluster } = useGetClusterUnique({
    brandCode: cluster.brandCode,
    code: cluster.code,
    month: cluster.month,
  })

  const handleClick = () => {
    if (brand) {
      dispatch(
        setCurrentCluster({
          brandCode: cluster.brandCode,
          code: cluster.code,
          month: cluster.month,
        }),
      )
    }

    navigate(PLP)
  }

  const handleCompareButtonClick = () => {
    currentCluster &&
      brand &&
      dispatch(
        toggleClusterCompare({
          brand: brand,
          cluster: currentCluster,
        }),
      )
  }

  const clusterCover = `${process.env.PUBLIC_URL}/cover/${brand?.brandCode}/${cluster.code}.jpg`

  return (
    <ClusterCardContainer>
      <ClusterImageContainer onClick={handleClick}>
        <CardHeaderImage src={clusterCover} alt="" />
        <BadgeContainer>
          <Badge>
            <GlassesIconContainer>
              <SmallSunglassesIcon /> {products.length}
            </GlassesIconContainer>
          </Badge>
        </BadgeContainer>
        <GradientOverlay />
        <ClusterCodeContainer>
          <ClusterCode>{cluster.code}</ClusterCode>
          <ClusterCodeIconContainer>
            <RightArrowIcon />
          </ClusterCodeIconContainer>
        </ClusterCodeContainer>
      </ClusterImageContainer>
      <ClusterCardPLPContainer>
        <ClusterCardDescription>{cluster.description}</ClusterCardDescription>
        <ProductImagesGridContainer>
          <ProductImagesGrid
            isShortList={products.length < 10}
            productListColumns={productListColumns}
          >
            {products.map((product, index) => (
              <ProductImageContainer
                key={`${product.shape}_${product.frontColor}_${product.frontMaterial}_${product.skuCode}_${product.size}_[${index}]`}
              >
                {product.imageUrl ? (
                  <ProductImageIcon src={product.imageUrl + '?imwidth=300'} />
                ) : (
                  <ProductNoImagePlaceholder />
                )}
              </ProductImageContainer>
            ))}
          </ProductImagesGrid>
        </ProductImagesGridContainer>
        <PlpButtonsContainer>
          <StyledButton
            onClick={handleCompareButtonClick}
            selected={isCompareSelected}
            isSelected={isCompareSelected}
            key={clusterKey + '_style'}
            icon={
              <IconWrapper>
                <CheckedIcon />
              </IconWrapper>
            }
          >
            {t('buttons.compare')}
          </StyledButton>
          <SelectButton
            type={ButtonType.CLUSTER}
            key={clusterKey}
            cluster={cluster}
            brandGroup={brand?.group || ''}
          />
        </PlpButtonsContainer>
      </ClusterCardPLPContainer>
    </ClusterCardContainer>
  )
}

export default ClusterCard
