import styled from 'styled-components'
import {
  BRAND_ICON_HEIGHT,
  BRAND_ICON_MAX_WIDTH,
  BRAND_ICON_WIDTH,
} from '../../constants/ui'
import { fluidSize } from '../../utils/style'

export const BrandsGridWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  marginBottom: theme.spacing(4),
}))

export const BrandsGridContainer = styled.div`
  color: white;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: ${fluidSize(BRAND_ICON_HEIGHT, 133, 1366, 1920)};
  grid-auto-flow: row;
  grid-gap: ${fluidSize(5, 8, 1366, 1920)};
  padding: 0 32px;
`

export const BrandIconContainer = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  border: `2px solid ${isSelected ? theme.palette.color.white.main : theme.palette.primary.main}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 8,
  backgroundColor: theme.palette.primary.main,
  cursor: 'pointer',
  userSelect: 'none',

  '&.sidebar-brand-icon-container': {
    minHeight: BRAND_ICON_HEIGHT,
    minWidth: BRAND_ICON_WIDTH,
  },
}))

export const BrandIcon = styled('img').withConfig({
  shouldForwardProp: (prop) => prop !== 'isPradaLineaRossa',
})<{ isPradaLineaRossa: boolean }>(({ isPradaLineaRossa }) => ({
  filter: isPradaLineaRossa ? 'invert(100%)' : 'brightness(0) invert(1)',
  maxWidth: BRAND_ICON_MAX_WIDTH,
  height: 'auto',
}))

export const BrandName = styled.span`
  max-width: ${BRAND_ICON_MAX_WIDTH}px;
  height: auto;
  text-transform: uppercase;
  text-align: center;
`

export const LoaderContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
`

BrandIconContainer.displayName = 'BrandIconContainer'
BrandIconContainer.defaultProps = {}
BrandIconContainer.propTypes = {}
