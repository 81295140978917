import AccountMenu from '../UI/MenuDialog/MenuDialog'
import {
  NavigationBarContainer,
  NavigationBarLeft,
  NavigationBarRight,
} from './NavigationBar.style'
import { CLUSTERS, PLP } from '../../constants/routes'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import BackCTA from '../UI/Arrows/BackArrow/BackArrow'
import MenuButton from '../MenuButton/MenuButton'
import { setResetSidebarView } from '../../redux/slices/ClusterSlice'

const NavigationBar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isClustersPage = location.pathname === CLUSTERS
  const isPlpPage = location.pathname === PLP

  const resetSidebarView = () => {
    if (!isClustersPage) {
      navigate(CLUSTERS)
    }
    dispatch(setResetSidebarView(true))
  }

  return (
    <NavigationBarContainer isClustersOrPlpPage={isClustersPage || isPlpPage}>
      {isClustersPage || isPlpPage ? (
        <NavigationBarLeft>
          <BackCTA destination={-1} />
          <MenuButton onClick={resetSidebarView} />
        </NavigationBarLeft>
      ) : null}
      <NavigationBarRight>
        <AccountMenu />
      </NavigationBarRight>
    </NavigationBarContainer>
  )
}

export default NavigationBar
