import { CheckedIcon, IconWrapper, StyledButton } from './SelectButton.style'
import {
  useDeleteClusterMutation,
  usePutClusterMutation,
} from '../../../../api/BrandsApi'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { AppState } from '../../../../redux/store'
import { ButtonType } from '../../../../types/common'
import { Cluster } from '../../../../types/clusters'
import { RuleSet } from 'styled-components'
import { toggleClusterSelection } from '../../../../redux/slices/ClusterSlice'
import { useTranslation } from 'react-i18next'

type Props = {
  type: ButtonType
  customStyle?: RuleSet<object>
  cluster: Cluster
  brandGroup: string
  onClick?(): void
}

const SelectButton: React.FC<Props> = ({
  type,
  customStyle,
  brandGroup,
  cluster,
  ...props
}) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const selectedClusters = useSelector(
    (state: AppState) => state.cluster.selected || [],
  )
  const [putCluster] = usePutClusterMutation()
  const [deleteCluster] = useDeleteClusterMutation()

  const [isSelectSelected, setIsSelectSelected] = useState(false)

  useEffect(() => {
    const selected = selectedClusters.some(
      ({ code, month, brandCode }) =>
        cluster.code === code &&
        cluster.month === month &&
        brandCode === cluster.brandCode,
    )

    setIsSelectSelected(selected)
  }, [selectedClusters, cluster.code, cluster.month, cluster.brandCode])

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setIsSelectSelected((prevSelected) => !prevSelected)

    const isOneClusterAlreadySelected = selectedClusters.some(
      ({ month, type, brandCode }) =>
        month === cluster.month &&
        type === cluster.type &&
        brandCode === cluster.brandCode,
    )

    let color = 'red'

    if (
      isOneClusterAlreadySelected &&
      brandGroup === 'el' &&
      !isSelectSelected
    ) {
      const alreadyCluster = selectedClusters.find(
        ({ type, month, brandCode }) =>
          month === cluster.month &&
          type === cluster.type &&
          brandCode === cluster.brandCode,
      )

      alreadyCluster && dispatch(toggleClusterSelection(alreadyCluster))
      color = 'cyan'

      console.log('%c%s', `color:${color}`, 'DELETE OLD SELECTED CLUSTER')
      alreadyCluster &&
        deleteCluster({
          brandCode: alreadyCluster.brandCode || '',
          clusterCode: alreadyCluster.code,
          clusterMonth: alreadyCluster.month,
        })
    }

    if (isSelectSelected) {
      console.log('%c%s', `color:${color}`, 'DELETE SELECTED CLUSTER')
      deleteCluster({
        brandCode: cluster.brandCode || '',
        clusterCode: cluster.code,
        clusterMonth: cluster.month,
      })
    } else {
      console.log('%c%s', `color:${color}`, 'PUT SELECTED CLUSTER')
      putCluster({
        brandCode: cluster.brandCode || '',
        clusterCode: cluster.code,
        clusterMonth: cluster.month,
      })
    }

    cluster && dispatch(toggleClusterSelection(cluster))

    props.onClick && props.onClick()
  }

  return (
    <StyledButton
      onClick={handleOnClick}
      customStyle={customStyle}
      selected={isSelectSelected}
      type={type}
      variant="contained"
    >
      {isSelectSelected && (
        <IconWrapper>
          <CheckedIcon />
        </IconWrapper>
      )}
      {isSelectSelected ? t('buttons.selected') : t('buttons.select')}
    </StyledButton>
  )
}

export default SelectButton
