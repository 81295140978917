import { RootState } from '../store'
import { createSlice } from '@reduxjs/toolkit'

type StateInterface = {
    width: number
    height: number
}

const initialState: StateInterface = {
    width: window.screen.availWidth,
    height: window.innerHeight,
}

export const WindowDimensionsSlice = createSlice({
    name: 'windowDimensions',
    initialState,

    reducers: {
        setWindowDimensions: (state, action) => {
            const { width, height } = action.payload

            state.width = width
            state.height = height
        },
    },
})

export const { setWindowDimensions } = WindowDimensionsSlice.actions

export const selectWindowDimensions = (state: RootState) => {
    return state.windowDimensions
}

export default WindowDimensionsSlice.reducer
