import React from 'react'
import {MenuButtonContainer} from './MenuButton.style'
import HamburgerMenuIcon from '../UI/HamburgerMenuIcon/HamburgerMenuIcon'

interface MenuButtonProps {
    onClick: () => void
}

const MenuButton: React.FC<MenuButtonProps> = ({ onClick }) => {
    return (

        <MenuButtonContainer onClick={onClick}>
            <HamburgerMenuIcon/>
        </MenuButtonContainer>
    )
}

export default MenuButton