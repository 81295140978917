import styled from 'styled-components'
import {pxToRem} from '../../../utils/style'
import {n120, n275} from '../../../utils/numbers'
import {Z_INDEX_LV4} from '../../../constants/ui'

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${Z_INDEX_LV4};
`

export const DialogWrapper = styled('div').withConfig({
    shouldForwardProp: (prop) => prop !== 'top' && prop !== 'right' && prop !== 'bottom' && prop !== 'left',
})<{ top?: number; right?: number; bottom?: number; left?: number }>(({ top, right, bottom, left, theme }) => ({
  position: 'absolute',
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
  top: top,
  left: left,
  right: right,
  bottom: bottom,
  background: theme.palette.color.white.main,
  padding: theme.spacing(5),
  borderRadius: 16,
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  zIndex: Z_INDEX_LV4,
  color: theme.palette.text.dark,
  minWidth: pxToRem(n275),
  minHeight: pxToRem(n120),
}))