import { useEffect, useState } from 'react'
import { dragScroll } from '../libs/general'

export const useDragScroll = (
  id: string,
  scrollDirection?: 'horizontal' | 'vertical'
) => {
  const [active, setActive] = useState(false)

  const handleClick = () => {
    if(!active){
      setActive(true)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick, true)
    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  })

  useEffect(() => {
    const horizontalScroll = scrollDirection === 'vertical' ? false : true
    const verticalScroll = scrollDirection === 'horizontal' ? false : true

    const parentElement = document.getElementById(id) as HTMLElement
    if (parentElement) {
      dragScroll(parentElement, horizontalScroll, verticalScroll)
    }

    return () => {
      if (parentElement) {
        parentElement.removeEventListener(
          'pointerdown',
          dragScroll(parentElement, horizontalScroll, verticalScroll)
        )
        window.removeEventListener(
          'pointerup',
          dragScroll(parentElement, horizontalScroll, verticalScroll)
        )
        window.removeEventListener(
          'pointermove',
          dragScroll(parentElement, horizontalScroll, verticalScroll)
        )
      }
    }
    // don't add dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active])
}
