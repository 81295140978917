import {fluidSize, pxToRem} from '../../../../utils/style'
import {n16, n48} from '../../../../utils/numbers'
import styled, { RuleSet, css } from 'styled-components'

import { Button } from '@mui/material'
import { ButtonType } from '../../../../types/common'

const hoverStyle = css`
  background-color: #bdd0f8;
`
export const StyledButton = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    prop !== 'customStyle' && prop !== 'selected' && prop !== 'type',
})<{
  type: ButtonType
  selected: boolean
  customStyle?: RuleSet<object>
}>`
  display: flex;
  column-gap: ${fluidSize(5)};

  border-radius: 8px;
  font-weight: bold;
  font-size: ${pxToRem(n16)};
  padding: ${fluidSize(9)} ${fluidSize(18)};
  color: ${(props) => props.theme.palette.text.dark};
  border: 2px solid ${(props) => props.theme.palette.text.dark};
  min-width: 170px;
  height: ${pxToRem(n48)};
  
  @media (hover: none) {
    &:hover {
      background-color: #ffffff;
    }
  }

  @media (hover: hover) {
    &:hover {
      ${hoverStyle};
    }
  }
  
  ${({ type }) =>
    type === 'plp' &&
    css`
      background-color: #ffffff;
    `}

  ${({ type, theme }) =>
    type === 'cluster' &&
    css`
      background-color: transparent;
    `}

  ${({ selected }) =>
    selected &&
    css`
      background-color: #bdd0f8;
      color: black;

      &:hover {
        background-color: #aabce3;
      }
    `}

  ${({ customStyle }) => customStyle}
`

export const IconWrapper = styled.div`
  border: ${fluidSize(1, 2)} solid black;
  border-radius: 50%;
  padding: ${fluidSize(2)};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckedIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='currentColor' xmlns='http://www.w3.org/2000/svg' %3E%3Cpath d='M15.802 3.052a.58.58 0 0 1-.18.42l-9.547 9.26a.963.963 0 0 1-.67.268.963.963 0 0 1-.67-.269L.18 8.313a.585.585 0 0 1 0-.84l.472-.458a.625.625 0 0 1 .867 0l3.67 3.56a.312.312 0 0 0 .431 0l8.665-8.401a.624.624 0 0 1 .866 0l.473.458c.115.11.18.262.18.42z'/%3E%3C/svg%3E");
  width: ${fluidSize(8)};
  height: ${fluidSize(8)};
  background-repeat: no-repeat;
  background-size: contain;
`
