import React from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {SxProps} from '@mui/material'

interface Props {
    sx: SxProps;
}

const UpArrow: React.FC<Props> = ({sx}) => {
    return (
        <KeyboardArrowUpIcon sx={sx} />
    )
}

export default UpArrow