import React from 'react';
import styled, { keyframes, css } from 'styled-components';

type LoaderProps = {
    size?: number;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div<{ size?: number }>`
    ${({ size, theme }) => css`
        display: inline-block;
        width: ${size ?? 50}px;
        height: ${size ?? 50}px;
        border: 4px solid ${theme.palette.color.white.main};
        border-top-color: ${theme.palette.primary.light};
        border-radius: 50%;
        animation: ${rotate} 2s linear infinite;
    `}
`;

const StyledLoader: React.FC<LoaderProps> = ({ size }) => {
    return <Loader size={size} />;
};

export default StyledLoader;
