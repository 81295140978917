import { useTranslation } from 'react-i18next'
import { Product } from '../../../types/products'
import {
  Price,
  ProductImage,
  ProductInfo,
  ProductTag,
  ProductTagWrapper,
  SkuCode,
  Wrapper,
} from './PlpProductCard.style'
import { Dispatch, SetStateAction } from 'react'

type Props = {
  product: Product
  setSelectedSkuCode: Dispatch<SetStateAction<string | null>>
}

const PlpProductCard: React.FC<Props> = ({ product, setSelectedSkuCode }) => {
  const { t } = useTranslation()

  const { skuCode, imageUrl, priceWholesale, priceSuggested, currency, tags } =
    product

  return (
    <Wrapper onClick={() => setSelectedSkuCode(skuCode)}>
      <ProductInfo>
        <SkuCode>{skuCode}</SkuCode>
        <div>
          <Price className='bolder'>
              {priceWholesale && parseFloat(priceWholesale) !== 0 ? `${currency} ${priceWholesale}` : null}
          </Price>
          <Price>
              {priceSuggested && parseFloat(priceSuggested) !== 0 ? `${t('currency.SRP')} ${priceSuggested}` : null}
          </Price>
        </div>
      </ProductInfo>

      <ProductImage>
          {imageUrl ? <img src={imageUrl + '?imwidth=300'} alt="product"/> : null}
      </ProductImage>

      <ProductTagWrapper>
        {!!tags.length ? (
          tags.map((tag) => <ProductTag>{tag}</ProductTag>)
        ) : (
          <ProductTag className='placeholder' />
        )}
      </ProductTagWrapper>
    </Wrapper>
  )
}

export default PlpProductCard
