import React from 'react'
import {Checkbox, SxProps} from '@mui/material'

interface SelectCheckboxProps {
    checked: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    sx: SxProps;
}


const SelectCheckbox: React.FC<SelectCheckboxProps> = ({ checked, onClick, sx }) => {
    return (
        <Checkbox
            checked={checked}
            onClick={onClick}
            sx={sx}
        />
    )
}

export default SelectCheckbox