import {createTheme} from '@mui/material/styles'

const theme = createTheme({
    typography: {
        fontFamily: ['Gilroy', 'sans-serif'].join(','),
        htmlFontSize: 16,
        body1: {
            fontSize: '1rem',
        },
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#1F3454',
            dark: '#011220',
            light: '#4A7CFD',
        },
        secondary: {
          main: '#c4d6f9'
        },
        success: {
            main: '#247534',
            light: '#d6f3e9',
            dark: '#035630',
        },
        error: {
            main: '#d32113',
            light: '#fde1e1',
            dark: '#7b0000',
        },
        warning: {
            main: '#dc9f03',
            light: '#fcf9db',
            dark: '#b68405',
        },
        info: {
            main: '#1465b4',
            light: '#dcedfc',
            dark: '#083867',
        },
        text: {
            dark: '#000000',
            light: '#ffffff',
        },
        color: {
            grey: {
                darker: '#616161',
                dark: '#767676',
                main: '#7a7a7a',
                light: '#d8d8d8',
                lightest: '#f7f6f4',
                ultralight: '#f6f6f6',
                smooth: '#f5f5f5'
            },
            azure: {
                main: '#5093f9'
            },
            frost: {
                main: '#c1d7fc'
            },
            ice: {
                main: '#f2f5f8'
            },
            space: {
                main: '#0e213d'
            },
            night: {
                main: '#001625'
            },
            black: {
                main: '#000000',
            },
            white: {
                main: '#ffffff',
                transparent: 'rgba(255, 255, 255, 0.2)',
            },
            backgrounds: {
                main: 'radial-gradient(circle at top, #08101D 55%, #193B6A 100%);',
                overlay: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 40%, rgba(0,0,0,0.4) 60%, rgba(0,0,0,0.8) 100%);',
                color1: '#ecdff1',
                color2: '#f5e6ea',
                color3: '#faf5e9',
                color4: '#f0eae2',
                color5: '#f7f6f4',
                color6: '#e6f9f5',
            },
            crm: {
                main: '#909090',
            },
        },
    },
    spacing: 4,
    breakpoints: {
        values: {
            xs: 375,
            sm: 600,
            md: 1024,
            lg: 1280,
            xl: 1440,
        },
    },
    shadows: [
        'none',
        '0 5.9px 11.8px 0 rgba(96, 97, 112, 0.16), 0 0 2.9px 0 rgba(40, 41, 61, 0.04)',
        '0px 1px 4px 2px rgba(0,0,0,0.15), 0px 2px 1px 0px rgba(0,0,0,0.13)',
        '0px 1px 2px 2px rgba(0,0,0,0.1), 0px 2px 1px 0px rgba(0,0,0,0.1)',
        'inset 0px 1px 2px 2px rgba(0,0,0,0.1), inset 0px 2px 1px 0px rgba(0,0,0,0.1)',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
        'none',
    ],
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    fontSize: 16,
                },
                body: {
                    fontSize: 16,
                },
            },
        },
    },
})

export default theme
