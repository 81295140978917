import {
  AppFirstRow,
  AppHeaderContainer,
  AppTitle,
  ClusterTitle,
} from './AppHeader.style'
import {DELETE_OLD_SELECTED_CLUSTER, DELETE_SELECTED_CLUSTER, EL, PUT_SELECTED_CLUSTER, TP} from '../../constants/ui'
import React, { useState } from 'react'
import {
  setELBrands,
  setOtherBrands,
  setSelectedBrandType,
} from '../../redux/slices/BrandsSlice'
import {
  useDeleteClusterMutation,
  useGetBrandsQuery,
  useGetClusterUnique,
  usePutClusterMutation
} from '../../api/BrandsApi'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../redux/store'
import { BrandCluster } from '../../types/clusters'
import { ButtonType } from '../../types/common'
import SelectButton from '../UI/Buttons/SelectButton/SelectButton'
import SelectionRow from '../SelectionRow/SelectionRow'
import ToggleButton from '../UI/Toggle/Toggle'
import { css } from 'styled-components'
import {toggleClusterSelection} from '../../redux/slices/ClusterSlice'
import { useTranslation } from 'react-i18next'

type Props = {
  plpCluster?: BrandCluster
}

interface ClusterInfo {
  clusterCode: string;
  clusterMonth: string;
}

const AppHeader: React.FC<Props> = ({ plpCluster }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { data: brands } = useGetBrandsQuery()
  const selectedBrandType = useSelector(
    (state: AppState) => state.brands.selectedBrandType
  )
  const currentBrand = useSelector((state: AppState) => state.brands.current)
  const [brandType, setBrandType] = useState(selectedBrandType)
  const isPlp = !!plpCluster

  const handleBrandChange = (newBrandSelection: string) => {
    if (newBrandSelection && newBrandSelection !== brandType) {
      setBrandType(newBrandSelection)
      if (newBrandSelection === EL) {
        dispatch(setELBrands(brands ?? []))
        dispatch(setSelectedBrandType(EL))
      } else if (newBrandSelection === TP) {
        dispatch(setOtherBrands(brands ?? []))
        dispatch(setSelectedBrandType(TP))
      }
    }
  }

  const typesOptions = [
    { value: EL, label: t('brandToggle.elBrands') },
    { value: TP, label: t('brandToggle.otherBrands') },
  ]

  const { data: currentCluster } = useGetClusterUnique({
    brandCode: currentBrand?.brandCode,
    code: plpCluster?.code,
    month: plpCluster?.month,
  })

  return (
    <AppHeaderContainer>
      {isPlp ? (
        <>
          <AppFirstRow>
            <AppTitle>
              {`${currentBrand?.brandName} (${plpCluster.products.length})`}
            </AppTitle>

            {currentCluster && <SelectButton
              type={ButtonType.PLP}
              key={`${currentCluster.code}${currentCluster.month}`}
              cluster={currentCluster}
              brandGroup={currentBrand?.group || ''}
            />}

          </AppFirstRow>
          <ClusterTitle>{plpCluster.code}</ClusterTitle>
        </>
      ) : (
        <>
          <AppFirstRow>
            <AppTitle>{t('home.chooseYourAssortment')}</AppTitle>
            <ToggleButton
              selType={brandType}
              onTypeChange={handleBrandChange}
              typesOptions={typesOptions}
              disabled={false}
            />
          </AppFirstRow>
          <SelectionRow />
        </>
      )}
    </AppHeaderContainer>
  )
}

export default AppHeader
