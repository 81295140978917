import moment, { Moment } from 'moment/moment'
import { n1 } from './numbers'
import { t } from 'i18next'

export enum Types {
    AP = 'AP',
    ZPFN = 'ZPFN',
    ZAFA = 'ZAFA',
}

export const tagTypeLabel = (
    tagType: string,
    isEmeaUser: boolean | undefined,
    isScreenDesktop: boolean,
) => {
    if (isEmeaUser) {
        switch (tagType) {
            case Types.AP:
                return isScreenDesktop
                    ? t('pages.ap.emeaTitle')
                    : t('pages.ap.mobileEmeaTitle')
            case Types.ZAFA:
                return isScreenDesktop
                    ? t('pages.zafa.emeaTitle')
                    : t('pages.zafa.mobileEmeaTitle')
            default:
                return isScreenDesktop
                    ? t('pages.oneLabel.title')
                    : t('pages.oneLabel.mobileEmeaTitle')
        }
    } else {
        switch (tagType) {
            case Types.AP:
                return t('pages.ap.title')
            case Types.ZAFA:
                return t('pages.zafa.title')
            default:
                return t('pages.zpfn.title')
        }
    }
}

export const justificationTagTypeLabel = (
    tagType: string,
    isEmeaUser: boolean | undefined,
) => {
    switch (tagType) {
        case Types.AP:
            return t('pages.apJustification.title')
        case Types.ZAFA:
            return t('pages.zafaJustification.title')
        default:
            return isEmeaUser
                ? t('pages.oneLabelJustification.title')
                : t('pages.zpfnJustification.title')
    }
}

export const getCurrentPeriodStartMonth = (divider: number): Moment => {
    const month = moment().month()
    const period = Math.floor(month / divider)
    return moment().month(period * divider)
}
export const getCurrentPeriodEndMonth = (divider: number): Moment => {
    const month = moment().month()
    const period = Math.floor(month / divider)
    return moment().month(period * divider + (divider - n1))
}

const handleGetPeriodLabel = (divider: number) => {
    const month = moment().month()
    const period = Math.floor(month / divider)

    return `Q${period + n1} ${getCurrentPeriodStartMonth(divider).format(
        'MMM',
    )}-${getCurrentPeriodEndMonth(divider).format('MMM')}`
}

export const handleQuarterCalc = (divider: number): string => {
    return handleGetPeriodLabel(divider)
}
