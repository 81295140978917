import styled from 'styled-components'

export const MenuItemsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 4),
}))

export const MenuTitle = styled('h3')(() => ({
  fontWeight: 'bolder',
}))

export const MenuDivider = styled('hr')(({ theme }) => ({
  border: 'none',
  height: 1,
  backgroundColor: theme.palette.color.grey.light,
  margin: theme.spacing(6, 0),
}))
