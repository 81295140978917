export const ROOT_PATH = '/'
export const HOME = '/home'

//Order
export const CLUSTERS = '/clusters'
export const PLP = '/plp'
export const MAINTENANCE = '/maintenance'

//Account
export const LOGIN = 'login'

export const MAIN_LOGIN_URL = `${process.env.REACT_APP_LOGIN_URL}`
