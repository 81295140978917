import React, {useEffect, useRef} from 'react'
import {useGetBrandsWithLogo} from '../../api/BrandsApi'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from '../../redux/store'
import {Brand} from '../../types/brands'
import {BrandIcon, BrandIconContainer, BrandName, LoaderContainer} from '../BrandsGrid/BrandsGrid.style'
import {ModifyCTA, SidebarContainer, SidebarWrapper} from './Sidebar.style'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {HOME} from '../../constants/routes'
import {setCurrentBrand} from '../../redux/slices/BrandsSlice'
import StyledLoader from '../UI/Loader/Loader'
import {PRADA_LINEA_ROSSA} from '../../constants/ui'
import {setResetSidebarView} from '../../redux/slices/ClusterSlice'

const Sidebar = () => {
    const sidebarContainerRef = useRef<HTMLDivElement>(null)
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { isLoading, error } = useGetBrandsWithLogo()
    const selectedBrands = useSelector((state: AppState) => state.brands.selectedBrands)
    const selectedBrandCode = useSelector((state: AppState) => state.brands.current)?.brandCode

    const resetSidebarView = useSelector((state: AppState) => state.cluster.resetSidebarView)

    const scrollToTop = () => {
        if (sidebarContainerRef.current) {
            sidebarContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    }

    useEffect(() => {
        dispatch(setResetSidebarView(false))
    }, [dispatch])

    useEffect(() => {
      try {
        if (resetSidebarView) {
          scrollToTop()
          dispatch(setCurrentBrand(selectedBrands[0]))
        }
      } catch (e) {
        console.error(e)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetSidebarView, scrollToTop])

    const handleBrandSelection = (brand: Brand) => {
        dispatch(setCurrentBrand(brand))
        dispatch(setResetSidebarView(false))
    }

    if (isLoading) return (
        <LoaderContainer>
            <StyledLoader size={120}/>
        </LoaderContainer>
    )

    if (error) {
        console.error(error)
    }

    return (
        <SidebarWrapper ref={sidebarContainerRef}>
            <SidebarContainer>
                <ModifyCTA onClick={() => navigate(HOME)}>{t('clusters.modify')}</ModifyCTA>
                {
                    selectedBrands.map((brand) => {
                        const isPradaLineaRossa = brand.brandName === PRADA_LINEA_ROSSA
                        return (
                            <BrandIconContainer
                                className='sidebar-brand-icon-container'
                                key={brand.brandCode}
                                onClick={() => handleBrandSelection(brand)}
                                isSelected={selectedBrandCode === brand.brandCode}
                            >
                                {brand.urlBrandLogo ?
                                    <BrandIcon isPradaLineaRossa={isPradaLineaRossa} src={brand.urlBrandLogo}/> :
                                    <BrandName>{brand.brandName}</BrandName>}
                            </BrandIconContainer>
                        )
                    })
                }
            </SidebarContainer>
        </SidebarWrapper>
    )
}

export default Sidebar