import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { User } from './../../types/user'

type StateInterface = {
  token: string | null
  signInName: string | null
  email: string | null
  name: string | null
}

const initialState: StateInterface = {
  token: null,
  signInName: null,
  email: null,
  name: null,
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,

  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      const user = action.payload

      state.token = user.token
      state.signInName = user.signInName
      state.email = user.email
      state.name = user.name
    },
  },
})

export const { setUser } = UserSlice.actions

export default UserSlice.reducer
