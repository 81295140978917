import { getSessionToken, setSessionToken } from '../../utils/persistance'
import { parseJwt, useGetUser } from '../../utils/utils';

import { Navigate } from 'react-router-dom';
import React from 'react'
import { setUser } from '../../redux/slices/UserSlice';
import { useDispatch } from 'react-redux';

export const UserControl: React.FC = () => {
  const tokenMatch = window.location.hash.match(/#id_token=(.+)/)
  const token = tokenMatch && tokenMatch[1] || getSessionToken()
  const dispatch = useDispatch()
  
  const user = useGetUser(token)

  if(token !== '') {
    setSessionToken(token)
    
    user && dispatch(setUser(user))  
  } 
  
  return <Navigate to="/home"></Navigate>
}

