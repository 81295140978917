import {Column, InfoProduct, Price, Release, SkuCode, Wrapper,} from './Pdp.style'
import {ProductImage, ProductTag, ProductTagWrapper,} from '../PlpProductCard/PlpProductCard.style'

import {PDP_IMG_WIDTH} from '../../../constants/ui'
import {Product} from '../../../types/products'
import {useTranslation} from 'react-i18next'

type Props = {
  product?: Product
}

const Pdp: React.FC<Props> = ({product}) => {
  const {t} = useTranslation()

  if (!product) return null

  const {
    skuCode,
    release,
    frontColor,
    frontMaterial,
    imageUrl,
    priceWholesale,
    priceSuggested,
    currency,
    tags,
  } = product

  return (
    <Wrapper>
      <Column className="left">
        <Release>{release}</Release>
        <SkuCode>{skuCode}</SkuCode>
        <span>
          <InfoProduct className="bolder">{frontColor}</InfoProduct>
          <InfoProduct>{frontMaterial && ` / ${frontMaterial}`}</InfoProduct>
        </span>
        <ProductTagWrapper>
          {tags.map((tag) => (
            <ProductTag>{tag}</ProductTag>
          ))}
        </ProductTagWrapper>
      </Column>
      <Column className="central">
        <ProductImage>
          {imageUrl ? <img src={imageUrl + `?imwidth=${PDP_IMG_WIDTH}`} alt="product"/> : null}
        </ProductImage>
      </Column>
      <Column className="right">
        {parseFloat(priceWholesale) > 0 && (
          <Price>
            {currency} {priceWholesale}
          </Price>
        )}
        {parseFloat(priceSuggested) > 0 && (
          <Price className="bolder">
            {t('currency.SRP')} {currency} {priceSuggested}
          </Price>
        )}
      </Column>
    </Wrapper>
  );
}

export default Pdp
