import React, { ChangeEvent } from 'react';
import {StyledForm, StyledInput, StyledLabel} from './RadioForm.style'

interface RadioOption {
    value: string;
    label: string;
}

interface RadioFormProps {
    options: RadioOption[];
    name: string;
    selectedValue: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const RadioForm: React.FC<RadioFormProps> = ({ options, name, selectedValue, onChange }) => {
    return (
        <StyledForm>
            {options.map((option) => (
                <StyledLabel key={option.value} checked={selectedValue === option.value}>
                    <StyledInput
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={onChange}
                    />
                    {option.label}
                </StyledLabel>
            ))}
        </StyledForm>
    );
}

export default RadioForm;
