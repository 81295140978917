import styled from 'styled-components'
import { fluidSize } from '../../../utils/style'

export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  color: black;
  width: 93vw;
  max-width: 1792px;
  height: 73vh;
  max-height: 786px;
  border-radius: 24px;
  padding: ${fluidSize(48)};
  display: grid;
  grid-template-columns: 1fr 50% 1fr;
`

export const Column = styled.div`
  font-family: Gilroy, sans-serif;

  &.left {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: ${fluidSize(53)} ${fluidSize(72)} ${fluidSize(55)} ${fluidSize(
        32
      )};
  }

  &.central {
    margin: auto 0;
  }

  &.right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: ${fluidSize(16)};
  }
`

export const Release = styled.span`
  font-weight: 400;
  font-size: ${fluidSize(17)};
`

export const SkuCode = styled.span`
  font-weight: 600;
  font-size: ${fluidSize(39)};
  white-space: nowrap;
`

export const InfoProduct = styled.span`
  font-size: ${fluidSize(17)};

  &.bolder {
    font-weight: 700;
  }
`

export const Price = styled.span`
  font-size: ${fluidSize(16)};

  &.bolder {
    font-size: ${fluidSize(22)};
    font-weight: 700;
  }
`
