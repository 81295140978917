import styled from 'styled-components'
import {
  MENU_EFFECT_Z_INDEX,
  NAVIGATION_BAR_MIN_HEIGHT,
} from '../../constants/ui'
import { fluidSize } from '../../utils/style'

export const NavigationBarContainer = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== 'isClustersOrPlpPage',
})<{ isClustersOrPlpPage: boolean }>(({ isClustersOrPlpPage, theme }) => ({
  display: 'flex',
  justifyContent: isClustersOrPlpPage ? 'space-between' : 'end',
  alignItems: 'center',
  minHeight: `${NAVIGATION_BAR_MIN_HEIGHT}px`,
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.color.white.main,
  width: '92%',
  margin: '0 auto',
  borderRadius: '24px 24px 0 0',
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: MENU_EFFECT_Z_INDEX,
  padding: theme.spacing(0, 8),
}))

export const NavigationBarLeft = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing(8)};
`

export const NavigationBarRight = styled.div`
  display: flex;
  position: relative;
`

export const UserIconContainer = styled.div`
  cursor: pointer;
`

export const UserNameSpan = styled.span`
  color: black;
  font-family: Gilroy, sans-serif;
  font-size: ${fluidSize(16)};
  font-weight: 400;
  text-transform: uppercase;
`

export const StyledHr = styled.hr`
  border: none;
  height: 1px;
  background-color: ${(props) => props.theme.palette.color.grey.light};
  margin: ${(props) => props.theme.spacing(6, 0)};
`
