import {BrandsApi} from '../../api/BrandsApi'
import {CmsApi} from '../../api/CmsApi'
import ProductsReducer from '../slices/ProductsSlice'
import UserReducer from '../slices/UserSlice'
import WindowDimensionsReducer from '../slices/WindowDimensionsSlice'
import brandsReducer from '../slices/BrandsSlice'
import clusterReducer from '../slices/ClusterSlice'
import genericReducer from '../slices/GenericSlice'
import {combineReducers} from 'redux'

const reducers = {
  brands: brandsReducer,
  cluster: clusterReducer,
  products: ProductsReducer,
  user: UserReducer,
  windowDimensions: WindowDimensionsReducer,
  generic: genericReducer,

  [BrandsApi.reducerPath]: BrandsApi.reducer,
  [CmsApi.reducerPath]: CmsApi.reducer,
}

const rootReducer = combineReducers(reducers)

export default rootReducer
