//##################################
// DECIMALS

export const decimalPointOne = 0.1
export const decimalPointTwentyFive = 0.25
export const decimalPointFive = 0.5
export const decimalPointSeven = 0.7
export const decimalTwoPointFive = 2.5
export const decimalSevenPointFive = 7.5
export const decimalEightPointDecimals = 8.71287
export const decimalElevenPointFive = 11.5
export const decimalTherteenPointFive = 13.5

//##################################
// NEGATIVES

export const numberNegativeOne = -1
export const numberNegativeFive = -5
export const numberNegativeEleven = -11

//##################################
// POSITIVES

export const n1 = 1
export const n2 = 2
export const n3 = 3
export const n4 = 4
export const n5 = 5
export const n6 = 6
export const n7 = 7
export const n8 = 8
export const n9 = 9
export const n10 = 10
export const n11 = 11
export const n12 = 12
export const n13 = 13
export const n14 = 14
export const n15 = 15
export const n16 = 16
export const n18 = 18
export const n20 = 20
export const n21 = 21
export const n22 = 22
export const n23 = 23
export const n24 = 24
export const n25 = 25
export const n27 = 27
export const n28 = 28
export const n30 = 30
export const n31 = 31
export const n32 = 32
export const n33 = 33
export const n35 = 35
export const n37 = 37
export const n40 = 40
export const n42 = 42
export const n43 = 43
export const n44 = 44
export const n48 = 48
export const n49 = 49
export const n50 = 50
export const n54 = 54
export const n56 = 56
export const n57 = 57
export const n60 = 60
export const n65 = 65
export const n70 = 70
export const n73 = 73
export const n75 = 75
export const n80 = 80
export const n82 = 82
export const n100 = 100
export const n120 = 120
export const n150 = 150
export const n160 = 160
export const n193 = 193
export const n240 = 240
export const n275 = 275
export const n600 = 600
export const n650 = 650
export const n1200 = 1200

//##################################

export const rgbFull = 255

export const circleFull = 360

export const n401 = 401

//##################################
// THEME NUMBERS

export const mainAlertHeight = 672
export const layoutPaperHeight = 83
export const layoutBoxWidth = 338
export const layoutBoxHeight = 141
export const muiDialogMinWidth = 443
export const muiDialogConfirmMaxWidth = 483
export const muiDialogMaxWidth = 869
