import {BrandsGridContainer, HomeWrapper} from './Home.style'
import AppHeader from '../../components/AppHeader/AppHeader'
import BrandsGrid from '../../components/BrandsGrid/BrandsGrid'
import ConfirmSelectionCTA from '../../components/ConfirmSelectionCTA/ConfirmSelectionCTA'

const Home = () => {

    return (
        <HomeWrapper>
            <AppHeader/>
            <BrandsGridContainer>
                <BrandsGrid/>
                <ConfirmSelectionCTA/>
            </BrandsGridContainer>
        </HomeWrapper>
    )
}

export default Home
