import styled from 'styled-components'
import {
  PLP_CARD_MIN_HEIGHT,
  PLP_CARD_WIDTH,
  NAVIGATION_BAR_MIN_HEIGHT,
} from '../../constants/ui'
import { fluidSize } from '../../utils/style'

const NUMBER_OF_ITEMS_PER_ROW = 5

export const PlpContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${NUMBER_OF_ITEMS_PER_ROW},
    ${fluidSize(PLP_CARD_WIDTH)}
  );
  grid-auto-rows: ${fluidSize(PLP_CARD_MIN_HEIGHT)};
  justify-content: center;
  grid-gap: ${fluidSize(8)};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  margin-bottom: ${fluidSize(NAVIGATION_BAR_MIN_HEIGHT)};
`
