import {Type} from '../../../types/clusters'
import {Product} from '../../../types/products'
import {PlanogramType} from '../Planogram'
import {ImageWrapper, ProductNoImagePlaceholder, Wrapper} from './PlanogramCard.style'

type Props = {
  planogram: PlanogramType
  selectedType: Type | null
}

const PlanogramCard: React.FC<Props> = ({planogram, selectedType}) => {
  const {brandName, urlBrandLogo, opticalProducts, sunProducts} = planogram
  const allProducts = [...sunProducts, ...opticalProducts]
  const thereAreSunProducts = !!sunProducts.length
  const thereAreOpticalProducts = !!opticalProducts.length

  if (
    (selectedType === 'opt' && !thereAreOpticalProducts) ||
    (selectedType === 'sun' && !thereAreSunProducts)
  ) {
    return null
  }

  return (
    <Wrapper>
      <ImageWrapper>
        {urlBrandLogo ? (
          <img src={urlBrandLogo + '?imwidth=300'} alt="logo"/>
        ) : (
          <span>{brandName}</span>
        )}
      </ImageWrapper>

      {(() => {
        const renderProducts = (products: Product[]) =>
          products.map(({ imageUrl }, index) => (
            <ImageWrapper key={`${imageUrl}-${index}`}>
              {imageUrl ? (
                <img src={imageUrl + '?imwidth=300'} alt="logo" />
              ) : (
                <ProductNoImagePlaceholder />
              )}
            </ImageWrapper>
          ))

        switch (selectedType) {
          case 'sun':
            return renderProducts(sunProducts)
          case 'opt':
            return renderProducts(opticalProducts)
          default:
            return renderProducts(allProducts)
        }
      })()}
    </Wrapper>
  )
}

export default PlanogramCard
