import React from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {SxProps} from '@mui/material'

interface Props {
    sx: SxProps;
}

const DownArrow: React.FC<Props> = ({sx}) => {
    return (
        <KeyboardArrowDownIcon sx={sx} />
    )
}

export default DownArrow