import styled from 'styled-components'
import { fluidSize } from '../../../utils/style'
import { MAIN_COLOR } from '../Planogram.style'

export const Wrapper = styled.div`
  border-radius: 10px;
  border: ${fluidSize(2)} solid ${MAIN_COLOR};
  padding: ${fluidSize(3)};
  display: flex;
`

export const TypeWrapper = styled.div<{ selected: boolean }>`
  border-radius: 6px;
  padding: ${fluidSize(10)} ${fluidSize(16)};
  font-family: Gilroy, sans-serif;
  font-size: ${fluidSize(16)};
  font-weight: 700;
  text-transform: uppercase;
  background-color: ${({ selected }) =>
    selected ? MAIN_COLOR : 'transparent'};
  cursor: pointer;
`
