import {
  BrandsGridArrowToggle,
  BrandsGridColumn,
  BrandsListTitle,
  BrandsListTitleContainer,
  BrandsListTitleNumber,
  ClustersContainer,
  ClustersFirstItemBrandName,
  ClustersHeaderContainer,
  ClustersPageWrapper,
  ClustersWrapper,
  MinimumOrderBoldSpan,
  MinimumOrderSpan,
  MinimumOrderSpanContainer,
  ReviewSelectionButton,
  ToggleContainer,
} from './Clusters.style'
import { OPT, SUN } from '../../constants/ui'
import {
  setClustersFromReview,
  setSwitchSelection,
} from '../../redux/slices/ClusterSlice'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetBrandDetailsQuery,
  useGetClustersReviewQuery,
} from '../../api/BrandsApi'

import { AppState } from '../../redux/store'
import ClusterCard from '../../components/ClusterCard/ClusterCard'
import { ClusterReviewPayload } from '../../types/clusters'
import CompareCounter from '../../components/CompareCounter/CompareCounter'
import LeftArrow from '../../components/UI/Arrows/LeftArrow/LeftArrow'
import { LoaderContainer } from '../../components/BrandsGrid/BrandsGrid.style'
import Modal from '../../components/Modal/Modal'
import Planogram from '../../components/Planogram/Planogram'
import RightArrow from '../../components/UI/Arrows/RightArrow/RightArrow'
import Sidebar from '../../components/Sidebar/Sidebar'
import StyledLoader from '../../components/UI/Loader/Loader'
import ToggleButton from '../../components/UI/Toggle/Toggle'
import { css } from 'styled-components'
import { setCurrentBrand } from '../../redux/slices/BrandsSlice'
import { useDragScroll } from '../../hooks/useDragScroll'
import { useGetUser } from '../../utils/utils'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const CLUSTERS_CONTAINER_ID = 'clusters-container'
const BRANDS_GRID_COLUMN = 'brands-grid-colunn'

const Clusters = () => {
  const { t } = useTranslation()
  const [isColumnVisible, setIsColumnVisible] = useState(true)
  const [openReview, setOpenReview] = useState(false)
  const dispatch = useDispatch()
  const selectedBrands = useSelector(
    (state: AppState) => state.brands.selectedBrands,
  )
  const compareClusters = useSelector(
    (state: AppState) => state.cluster.compare,
  )
  const currentBrand = useSelector((state: AppState) => state.brands.current)
  const resetSidebarView = useSelector(
    (state: AppState) => state.cluster.resetSidebarView,
  )
  const switchSelection = useSelector(
    (state: AppState) => state.cluster.switchSelection,
  )

  const minimumOrderSun = (currentBrand || selectedBrands?.[0])?.minimumOrderSun
  const minimumOrderOpt = (currentBrand || selectedBrands?.[0])?.minimumOrderOpt

  const { data: brandDetails, error } = useGetBrandDetailsQuery(
    currentBrand?.brandCode || selectedBrands[0]?.brandCode,
  )

  const user = useGetUser()

  const { data: clusterReviews, isLoading } = useGetClustersReviewQuery(
    user as ClusterReviewPayload,
  )

  useEffect(() => {
    if (clusterReviews?.length) {
      dispatch(setClustersFromReview(clusterReviews))
    }
  }, [clusterReviews, dispatch])

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setCurrentBrand(currentBrand || selectedBrands[0]))
  }, [dispatch, currentBrand, selectedBrands, navigate])

  useEffect(() => {
    if (resetSidebarView) {
      dispatch(setSwitchSelection(SUN))
      dispatch(setCurrentBrand(selectedBrands[0]))
    }
  }, [dispatch, resetSidebarView, selectedBrands])

  const handleProductTypeChange = useCallback(
    (newType: 'opt' | 'sun' | null) => {
      newType && dispatch(setSwitchSelection(newType))
    },
    [dispatch],
  )

  const isSelectedBrandOpticalOnly = brandDetails?.every(
    ({ type }) => type === 'opt',
  )
  const isSelectedBrandSunOnly = brandDetails?.every(
    ({ type }) => type === 'sun',
  )

  useEffect(() => {
    if (isSelectedBrandOpticalOnly) {
      handleProductTypeChange('opt')
    }
    if (isSelectedBrandSunOnly) {
      handleProductTypeChange('sun')
    }
  }, [
    handleProductTypeChange,
    isSelectedBrandOpticalOnly,
    isSelectedBrandSunOnly,
  ])

  useDragScroll(CLUSTERS_CONTAINER_ID, 'horizontal')
  useDragScroll(BRANDS_GRID_COLUMN, 'vertical')

  if (isLoading) {
    return (
      <LoaderContainer>
        <StyledLoader size={120} />
      </LoaderContainer>
    )
  }

  if (error) {
    console.log(error)
  }

  const toggleColumnVisibility = () => {
    setIsColumnVisible(!isColumnVisible)
  }

  const productTypes = [
    { value: SUN, label: t('productType.sun') },
    { value: OPT, label: t('productType.opt') },
  ]

  const renderMinimumOrderContent = () => {
    let minimumOrderContent = null

    if (switchSelection === SUN && !!minimumOrderSun) {
      minimumOrderContent = minimumOrderSun
    } else if (switchSelection === OPT && !!minimumOrderOpt) {
      minimumOrderContent = minimumOrderOpt
    }

    if (!minimumOrderContent) {
      return null
    }

    return (
      <>
        <MinimumOrderSpan>{t('clusters.minimumOrder')}</MinimumOrderSpan>&nbsp;
        <MinimumOrderBoldSpan>{minimumOrderContent}</MinimumOrderBoldSpan>
      </>
    )
  }

  return (
    <ClustersPageWrapper>
      <BrandsGridArrowToggle
        isColumnVisible={isColumnVisible}
        onClick={toggleColumnVisibility}
      >
        {isColumnVisible ? <LeftArrow /> : <RightArrow />}
      </BrandsGridArrowToggle>
      <BrandsGridColumn
        id={BRANDS_GRID_COLUMN}
        isColumnVisible={isColumnVisible}
      >
        <BrandsListTitleContainer>
          <BrandsListTitle>{t('brandCategory.brandsCapital')}</BrandsListTitle>
          <BrandsListTitleNumber>{selectedBrands.length}</BrandsListTitleNumber>
        </BrandsListTitleContainer>
        <Sidebar />
      </BrandsGridColumn>
      <ClustersWrapper isColumnVisible={isColumnVisible}>
        <ClustersHeaderContainer>
          <ClustersFirstItemBrandName>
            {currentBrand
              ? currentBrand.brandName
              : selectedBrands?.[0]?.brandName}
          </ClustersFirstItemBrandName>
          <MinimumOrderSpanContainer>
            {renderMinimumOrderContent()}
          </MinimumOrderSpanContainer>
        </ClustersHeaderContainer>
        <ClustersContainer id={CLUSTERS_CONTAINER_ID}>
          {brandDetails &&
            brandDetails
              .filter((cluster) => cluster.type === switchSelection)
              .map((cluster) => (
                <ClusterCard
                  key={
                    cluster.code + '-' + cluster.month + '-' + cluster.brandCode
                  }
                  cluster={cluster}
                />
              ))}
        </ClustersContainer>
      </ClustersWrapper>
      <ToggleContainer>
        {!!compareClusters.length && <CompareCounter />}
        <ReviewSelectionButton onClick={() => setOpenReview(true)}>
          {t('clusters.reviewSelection')}
        </ReviewSelectionButton>
        <ToggleButton
          selType={switchSelection}
          onTypeChange={handleProductTypeChange}
          typesOptions={productTypes}
          disabled={!!isSelectedBrandSunOnly || !!isSelectedBrandOpticalOnly}
        />
      </ToggleContainer>

      <Modal
        hideCloseButton
        visible={openReview}
        onClose={() => {}}
        customStyle={css`
          background-color: rgb(20, 32, 52);
        `}
      >
        <Planogram onClose={() => setOpenReview(false)} />
      </Modal>
    </ClustersPageWrapper>
  )
}

export default Clusters
