import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEN from './assets/locales/en/translation.json'
import translationIT from './assets/locales/it/translation.json'

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    it: {
        translation: translationIT,
    },
}

i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        order: ['navigator', 'localStorage'],
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
