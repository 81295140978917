import React, { ReactNode, ReactElement, MouseEventHandler } from 'react'
import {UnstyledButton} from './Button.style'

interface ButtonProps {
    children: ReactNode
    onClick?: MouseEventHandler<HTMLButtonElement>
    className?: string
    type?: 'button' | 'submit' | 'reset'
    icon?: ReactElement
    selected?: boolean
}

const Button: React.FC<ButtonProps> = ({ children, onClick, className = '', type = 'button', icon, selected = false, ...props }) => {
    return (
        <UnstyledButton
            type={type}
            className={`button ${className} ${selected ? 'selected' : ''}`}
            onClick={onClick}
            {...props}
        >
            {selected && icon && <span className="button-icon">{icon}</span>}
            {children}
        </UnstyledButton>
    )
}

export default Button
