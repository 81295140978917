import { PayloadAction, createSlice } from '@reduxjs/toolkit'

type StateInterface = {
  downloadWasClicked: boolean
}

const initialState: StateInterface = {
  downloadWasClicked: false,
}

export const GenericSlice = createSlice({
  name: 'generic',
  initialState,

  reducers: {
    setDownloadWasClicked: (state, { payload }: PayloadAction<boolean>) => {
      state.downloadWasClicked = payload
    },
  },
})

export const { setDownloadWasClicked } = GenericSlice.actions

export default GenericSlice.reducer
