import styled, { css } from 'styled-components'
import { fluidSize } from '../../utils/style'

export const Wrapper = styled.div`
  padding: ${fluidSize(8)};
  background-color: #c5d6f9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  font-family: Gilroy, sans-serif;
  color: black;
`

export const Compare = styled.span`
  font-size: ${fluidSize(16)};
  text-transform: capitalize;
`

export const Counter = styled.div`
  font-size: ${fluidSize(18)};
  font-weight: 700;
  margin: 0 ${fluidSize(16)} 0 ${fluidSize(8)};
`

export const CompareIconWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => prop !== 'darker',
})<{ darker: boolean }>`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${fluidSize(32)};
  height: ${fluidSize(32)};
  background-color: #356cf8;
  opacity: ${({ darker }) => (darker ? 1 : 0.5)};
  transition: opacity ease-out 200ms;
  cursor: pointer;
`

export const ChevronIcon = styled.div`
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='16' viewBox='0 0 10 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath id='4273_vector' d='M9.45355 7.99548C9.45355 7.76777 9.36247 7.54014 9.18939 7.38525L1.96716 0.336045C1.81233 0.181232 1.61196 0.0901489 1.38428 0.0901489C0.910686 0.0901489 0.546386 0.445394 0.546386 0.918926C0.546386 1.14664 0.637463 1.35609 0.783183 1.51098L7.91436 8.48729L7.91436 7.51278L0.783184 14.4891C0.637464 14.6349 0.546387 14.8443 0.546387 15.0811C0.546387 15.5547 0.910687 15.9099 1.38428 15.9099C1.61196 15.9099 1.81233 15.8188 1.97627 15.664L9.18939 8.6148C9.36247 8.44175 9.45355 8.23228 9.45355 7.99548Z' fill='white'/%3E%3C/svg%3E%0A");
  width: 50%;
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translateX(25%);
`

export const CompareWarning = styled.div`
  background-color: #c5d6f9;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(197, 214, 249, 0.6);
  color: black;
  padding: ${fluidSize(16)};
  display: flex;
  max-width: ${fluidSize(320)};
  cursor: pointer;

  p {
    margin: 0;
  }
`

export const CloseIconWrapper = styled.div`
  border: ${fluidSize(1, 2)} solid #356cf8;
  width: ${fluidSize(22)};
  height: ${fluidSize(22)};
  flex-shrink: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    height: 50%;
    width: 50%;
    fill: black;
  }
`

export const CompareCardsWrapper = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
`

export const warningModalStyle = css`
  background-color: rgba(1, 18, 32, 0.45);
  backdrop-filter: blur(5px);

  .modal-wrapper {
    top: ${fluidSize(16)};
    right: ${fluidSize(16)};
    left: unset;
    transform: unset;
  }
`

export const compareModalStyle = css`
  background-color: #1f3454;

  .modal-close-icon-wrapper {
    margin-right: ${fluidSize(30)};
    width: ${fluidSize(20)};
    height: ${fluidSize(20)};
    background-color: transparent;
    border: ${fluidSize(2)} solid #356cf8;

    &:hover {
      background-color: transparent;
    }

    svg {
      fill: white;
    }
  }
`
