import React, { ReactNode } from 'react'
import { AppWrapper } from './BaseLayout.style'

type Props = {
  children: ReactNode
}

const BaseLayout: React.FC<Props> = ({ children }) => {
  return <AppWrapper>{children}</AppWrapper>
}

export default BaseLayout
