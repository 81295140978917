import { IconButton, useTheme } from '@mui/material'
import * as React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Dialog from '@mui/material/Dialog'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { MAIN_LOGIN_URL } from '../../../constants/routes'
import { Z_INDEX_LV1 } from '../../../constants/ui'
import { AppState } from '../../../redux/store'
import {
  UserIconContainer,
  UserNameSpan,
} from '../../NavigationBar/NavigationBar.style'
import { CircleIcon } from '../Icons/Icons'
import UserIcon from '../UserIcon/UserIcon'
import { MenuDivider, MenuItemsContainer, MenuTitle } from './MenuDialog.style'

const emails = ['username@gmail.com', 'user02@gmail.com']

export interface SimpleDialogProps {
  open: boolean
  selectedValue: string
  onClose: (value: string) => void
}

function SimpleDialog(props: SimpleDialogProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const { onClose, selectedValue, open } = props
  const user = useSelector((state: AppState) => state.user)

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleLogout = () => {
    try {
      sessionStorage.removeItem('token')
      window.location.href = MAIN_LOGIN_URL!
    } catch (error) {
      console.error('Error:', error)
    }
  }

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          position: 'absolute',
          bottom: theme.spacing(10),
          right: '2%',
          margin: 0,
          minWidth: 280,
          minHeight: 240,
          border: `2px solid ${theme.palette.primary.light}`,
          borderRadius: 8,
          zIndex: Z_INDEX_LV1,
        },
      }}
    >
      <MenuItemsContainer>
        <MenuTitle>{t('nav.account')}</MenuTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CircleIcon htmlColor={theme.palette.primary.light} />
        </IconButton>
        <UserNameSpan>{user.name}</UserNameSpan>
        <MenuDivider />
        <List
          sx={{
            pt: 0,
            textDecoration: 'underline',
            fontWeight: 600,
            cursor: 'pointer',
          }}
          onClick={handleLogout}
        >
          <ListItem disableGutters>{t('nav.logout')}</ListItem>
        </List>
      </MenuItemsContainer>
    </Dialog>
  )
}

export default function AccountMenu() {
  const [open, setOpen] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(emails[1])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (value: string) => {
    setOpen(false)
    setSelectedValue(value)
  }

  return (
    <div>
      <UserIconContainer onClick={handleClickOpen}>
        <UserIcon />
      </UserIconContainer>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  )
}
