import { n2, n3, n8, rgbFull } from './numbers'

const RATIO = 16

export const pxToRem = (n: number): string => `${n / RATIO}rem`

export const fluidSize = (
  minSize: number,
  maxSize?: number,
  minVW?: number,
  maxVW?: number,
  unit?: string
): string =>
  `calc(${minSize}${unit || 'px'} + (${maxSize || minSize * 2} - ${minSize})*((100vw - ${minVW || 1366}px)/(${maxVW || 3840} - ${minVW || 1366})))`

export const fluidSizeNumber = (
  minSize: number,
  maxSize?: number,
  minVW?: number,
  maxVW?: number,
  decimal?: number
): number => {
  const viewportWidth = window.innerWidth

  const result =
    minSize +
    ((maxSize || minSize * 2) - minSize) *
      ((viewportWidth - (minVW || 1366)) / ((maxVW || 3840) - (minVW || 1366)))

  return parseFloat(result.toFixed(decimal || 2))
}

export const hexToRgbA = (hex: string, opacity: string) => {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === n3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [
        (parseInt(c) >> (n8 * n2)) & rgbFull,
        (parseInt(c) >> n8) & rgbFull,
        parseInt(c) & rgbFull,
      ].join(',') +
      `,${opacity})`
    )
  }
  throw new Error('Bad Hex')
}
