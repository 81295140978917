export const Z_INDEX_LV5 = 5
export const Z_INDEX_LV4 = 4
export const Z_INDEX_LV3 = 3
export const Z_INDEX_LV2 = 2
export const Z_INDEX_LV1 = 1

export const MENU_EFFECT_Z_INDEX = 1500

export const gridColumns = {
  xs: 8,
  sm: 8,
  md: 8,
  lg: 12,
  xl: 12,
}

// Brand types
export const EL = 'el'
export const TP = 'tp'

// Product types
export const SUN = 'sun'
export const OPT = 'opt'

// Measurement constants
export const CLUSTER_CARD_WIDTH = 412
export const CLUSTER_CARD_HEIGHT = 690
export const CLUSTER_IMAGE_CONTAINER_HEIGHT = 400
export const CLUSTER_PLP_CONTAINER_HEIGHT = 407

export const BRANDS_GRID_COLUMN_WIDTH = 240

export const NAVIGATION_BAR_MIN_HEIGHT = 90

export const BRAND_ICON_WIDTH = 154
export const BRAND_ICON_MAX_WIDTH = 93
export const BRAND_ICON_HEIGHT = 97

export const PLP_CARD_WIDTH = 254
export const PLP_CARD_HEIGHT = 307
export const PLP_CARD_MIN_HEIGHT = 254

export const PDP_IMG_WIDTH = 1500

// Brand categories
export const ALL_BRANDS = 'all'
export const LIFESTYLE = 'Lifestyle'
export const LUXURY = 'Luxury'
export const PREMIUM_FASHION = 'Premium Fashion'
export const FAST_FASHION = 'Fast Fashion'
export const SPORT_PERFORMANCE = 'Sport Performance'
export const LOCAL_COLLECTION = 'Local Collection'

// Brand exceptions
export const PRADA_LINEA_ROSSA = 'Prada Linea Rossa'

// Actions
export const DELETE_OLD_SELECTED_CLUSTER = 'DELETE OLD SELECTED CLUSTER'
export const DELETE_SELECTED_CLUSTER = 'DELETE SELECTED CLUSTER'
export const PUT_SELECTED_CLUSTER = 'PUT SELECTED CLUSTER'
