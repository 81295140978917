import {ALL_BRANDS, EL, TP} from '../../constants/ui'
import {Brand, BrandGroup} from '../../types/brands'
import {PayloadAction, createSlice} from '@reduxjs/toolkit'

import {RootState} from '../store'
import moment from 'moment'

type BrandsJustification = BrandGroup

type StateInterface = {
    brands: Brand[]
    brandsJustification: BrandsJustification[]
    allChecked: {
        [productLabelTag: string]: boolean
    }
    selectedBrands: Brand[]
    displayedBrands: Brand[]
    elBrands: Brand[]
    otherBrands: Brand[]
    selectedCount: number
    totalBrandsCount: number
    totalELBrandsCount: number
    totalOtherBrandsCount: number
    allBrands: Brand[]
    selectedBrandType: string
    selectedBrandCategory: string
    current: Brand | null
}

const initialState: StateInterface = {
    brands: [],
    displayedBrands: [],
    brandsJustification: [],
    allChecked: {},
    allBrands: [],
    selectedBrands: [],
    elBrands: [],
    otherBrands: [],
    selectedCount: 0,
    totalBrandsCount: 0,
    totalELBrandsCount: 0,
    totalOtherBrandsCount: 0,
    selectedBrandType: EL,
    selectedBrandCategory: ALL_BRANDS,
    current: null
}

export const BrandsSlice = createSlice({
    name: 'brands',
    initialState,

    reducers: {
        setCurrentBrand: (state, action: PayloadAction<Brand | null>) => {
            state.current = action.payload
        },
        setBrands: (state, action: PayloadAction<Brand[]>) => {
            state.brands = action.payload
        },
        setAllChecked: (state, action) => {
            state.allChecked = action.payload
        },
        addBrandsJustification: (state, action: PayloadAction<BrandGroup>) => {
            state.brandsJustification.push(action.payload)
        },
        removeBrandsJustification: (state, action) => {
            state.brandsJustification = state.brandsJustification.filter(
                item => item.brand_id !== action.payload.brand_id,
            )
        },
        resetBrandsJustification: state => {
            state.brandsJustification = []
        },
        setAllBrands: (state, action: PayloadAction<Brand[]>) => {
            state.allBrands = action.payload
            state.totalBrandsCount = action.payload.length
        },
        setDisplayedBrands: (state, { payload }: PayloadAction<Brand[]>) => {
            state.displayedBrands = payload
        },
        setELBrands: (state, action: PayloadAction<Brand[]>) => {
            state.elBrands = action.payload.filter(brand => brand.group === EL)
            state.totalBrandsCount = action.payload.length
        },
        setOtherBrands: (state, action: PayloadAction<Brand[]>) => {
            state.otherBrands = action.payload.filter(brand => brand.group === TP)
            state.totalBrandsCount = action.payload.length
        },
        setSelectedBrandType: (state, action: PayloadAction<'el' | 'tp'>) => {
            state.selectedBrandType = action.payload;
        },
        setSelectedBrandCategory: (state, action: PayloadAction<string>) => {
            state.selectedBrandCategory = action.payload;
        },
        toggleBrandSelection: (state, action: PayloadAction<Brand>) => {
            const brand = action.payload
            const currentGroup = brand.group
            const index = state.selectedBrands.findIndex(b => b.brandCode === brand.brandCode)
            if (state.selectedBrandType !== currentGroup) {
                state.selectedBrands = [];
                state.selectedBrandType = currentGroup;
            }
            if (index >= 0) {
                state.selectedBrands.splice(index, 1)
            } else {
                state.selectedBrands.push(brand)
                state.selectedBrands.sort((a, b) => a.brandName.localeCompare(b.brandName))
            }
        },
        resetSelectedBrands: (state) => {
            state.selectedBrands = []
            state.selectedCount = 0
        },
        resetELBrands: (state) => {
            state.selectedBrands = state.selectedBrands.filter(brand => brand.group !== EL);
            state.elBrands = [];
            state.selectedCount = state.selectedBrands.length;
        },
        resetOtherBrands: (state) => {
            state.selectedBrands = state.selectedBrands.filter(brand => brand.group !== TP);
            state.otherBrands = [];
            state.selectedCount = state.selectedBrands.length;
        },
        setTotalBrandsCount: (state, action: PayloadAction<number>) => {
            state.totalBrandsCount = action.payload
        },
        setTotalELBrandsCount: (state, action: PayloadAction<number>) => {
            state.totalELBrandsCount = action.payload
        },
        setTotalOtherBrandsCount: (state, action: PayloadAction<number>) => {
            state.totalOtherBrandsCount = action.payload
        },
        selectAllBrands: (state, action: PayloadAction<Brand[]>) => {
            state.selectedBrands = action.payload
            state.selectedCount = action.payload.length
        },
        selectBrand: (state, action: PayloadAction<Brand>) => {
            state.selectedBrands.push(action.payload);
            state.selectedBrands = [...state.selectedBrands]
                .sort((a, b) => a.brandName.localeCompare(b.brandName));
        },
        deselectBrand: (state, action: PayloadAction<number>) => {
            state.selectedBrands = state.selectedBrands.filter(brand => parseInt(brand.brandCode) !== action.payload)
        },
    },
})

export const {
    setCurrentBrand,
    setBrands,
    setAllChecked,
    addBrandsJustification,
    removeBrandsJustification,
    resetBrandsJustification,
    toggleBrandSelection,
    setTotalBrandsCount,
    selectAllBrands,
    setAllBrands,
    resetSelectedBrands,
    setELBrands,
    setOtherBrands,
    setSelectedBrandType,
    resetELBrands,
    resetOtherBrands,
    setTotalELBrandsCount,
    setTotalOtherBrandsCount,
    setSelectedBrandCategory,
    selectBrand,
    deselectBrand,
    setDisplayedBrands
} = BrandsSlice.actions

export const selectBrands = (state: RootState) => {
    return state.brands
}

export const selectBrandJustification = (state: RootState) => {
    return state.brands.brandsJustification
        .slice()
        .sort((a, b) => moment(b.date_time).diff(a.date_time))
}
export const selectAllChecked = (state: RootState) => {
    return state.brands.allChecked
}

export default BrandsSlice.reducer
