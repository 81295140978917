import React from 'react'
import {UserIconContainer} from './UserIcon.style'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';

const UserIcon = () => {
    return (
        <UserIconContainer>
            <PersonOutlineIcon />
        </UserIconContainer>
    )
}

export default UserIcon