import { AppStore, AppState } from './redux/store'

const LOCAL_STATE_VAR_NAME = 'rc-state'

export const loadState = (): Partial<AppState> => {
    try {
        const serializedState = localStorage.getItem(LOCAL_STATE_VAR_NAME)
        if (serializedState === null) {
            return {}
        }
        return JSON.parse(serializedState)
    } catch (err) {
        return {}
    }
}

export const saveState = (state: AppState | {}) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem(LOCAL_STATE_VAR_NAME, serializedState)
    } catch {
        // ignore write errors
    }
}
export const resetLocalStorageState = () => saveState({})

type reducerKeys = keyof AppState
