import styled from 'styled-components'

const WIDTH = 195
const WIDTH_SMALLER_SCREEN = 130

export const Wrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  border-radius: 16px;
  padding: 24px;
  background-color: white;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${WIDTH}px, 1fr));
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
  grid-gap: 24px;

  @media (max-height: 1023px) {
    grid-template-columns: repeat(
      auto-fill,
      minmax(${WIDTH_SMALLER_SCREEN}px, 1fr)
    );
  }
`

export const ImageWrapper = styled.div`
  box-sizing: border-box;
  min-width: ${WIDTH}px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  
  img {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: ${WIDTH / 2}px;
    object-fit: contain;
    -webkit-user-drag: none;

    @media (max-height: 1023px) {
      width: ${WIDTH_SMALLER_SCREEN}px;
      height: ${WIDTH_SMALLER_SCREEN / 2}px;
    }
  }

  span {
    color: black;
  }
`

export const ProductNoImagePlaceholder = styled('span')(() => ({
  minWidth: 100,
  display: 'flex',
  alignItems: 'center',
  padding: 4
}))
