import styled from 'styled-components'
import { BRANDS_GRID_COLUMN_WIDTH, Z_INDEX_LV2 } from '../../constants/ui'
import { pxToRem } from '../../utils/style'
import { n120, n14, n24, n32 } from '../../utils/numbers'
import Button from '../../components/UI/Buttons/Button/Button'

export const ClustersPageWrapper = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
`

export const ClustersContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing(4)};
  margin: ${(props) => props.theme.spacing(8, 0)};
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

export const ClustersWrapper = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== 'isColumnVisible',
})<{ isColumnVisible: boolean }>(({ isColumnVisible, theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'scroll',
  marginLeft: isColumnVisible ? 0 : theme.spacing(-50),
  position: 'relative',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
}))

export const ClustersFirstItemBrandName = styled.h1`
  font-size: ${pxToRem(n32)};
  font-weight: bolder;
`
export const MinimumOrderSpanContainer = styled.div``

export const MinimumOrderSpan = styled.span`
  font-size: ${pxToRem(n14)};
`

export const MinimumOrderBoldSpan = styled.span`
  font-size: ${pxToRem(n14)};
  font-weight: bolder;
`

export const BrandsGridColumn = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== 'isColumnVisible',
})<{ isColumnVisible: boolean }>(({ isColumnVisible, theme }) => ({
  transform: isColumnVisible ? 'translateX(0)' : 'translateX(-87%)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  transition: 'transform 0.1s ease',
  minWidth: BRANDS_GRID_COLUMN_WIDTH,
  maxWidth: BRANDS_GRID_COLUMN_WIDTH,
  overflowY: 'scroll',
  marginRight: theme.spacing(8),
  borderRight: `1px solid ${theme.palette.color.grey.darker}`,
  padding: theme.spacing(0, 2, 24, 0),

  '&::-webkit-scrollbar': {
    width: 0,
  },
}))

export const BrandsListTitleContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: ${pxToRem(n24)};
  text-align: center;
  gap: ${(props) => props.theme.spacing(4)};
`

export const BrandsListTitle = styled.p``

export const BrandsListTitleNumber = styled.span`
  font-weight: bolder;
`

export const BrandsGridArrowToggle = styled('div').withConfig({
  shouldForwardProp: (prop) => prop !== 'isColumnVisible',
})<{ isColumnVisible: boolean }>(({ isColumnVisible, theme }) => ({
  position: 'absolute',
  left: isColumnVisible ? theme.spacing(58) : theme.spacing(12),
  transform: `${isColumnVisible ? 'translateX(0)' : 'translateX(-100%)'}`,
  top: '40%',
  zIndex: Z_INDEX_LV2,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: pxToRem(n32),
  maxWidth: pxToRem(n32),
  minHeight: pxToRem(n120),
  maxHeight: pxToRem(n120),
  borderRadius: 8,
  backgroundColor: theme.palette.primary.main,
  cursor: 'pointer',
}))

export const ToggleContainer = styled.div`
  position: absolute;
  display: flex;
  right: ${(props) => props.theme.spacing(6)};
  top: ${(props) => props.theme.spacing(6)};
  gap: ${(props) => props.theme.spacing(6)};
  display: flex;
  align-items: center;
`

export const ClustersHeaderContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  gap: ${(props) => props.theme.spacing(4)};
`

export const ReviewSelectionButton = styled(Button)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: 16,
  textTransform: 'uppercase',
  border: `2px solid ${theme.palette.primary.light}`,
  padding: theme.spacing(1, 4),
  borderRadius: 8,
  height: 48,
}))
