import styled from 'styled-components'

export const SidebarWrapper = styled('div')(({ theme }) => ({
  minWidth: 200,
  maxWidth: 200,
  flexGrow: 1,
  marginBottom: theme.spacing(4),
  maxHeight: '100vh',
}))

export const ModifyCTA = styled('p')(() => ({
  fontSize: 14,
  textTransform: 'uppercase',
  textDecoration: 'underline',
  cursor: 'pointer',
  fontWeight: 'bolder',
}))

export const SidebarContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  alignItems: 'center',
  margin: 0,
  paddingBottom: theme.spacing(30),
  color: theme.palette.color.white.main,
  gap: theme.spacing(1.25),

  '&::-webkit-scrollbar': {
    width: 0,
  },
  '&': {
    scrollbarWidth: 'none',
  },
}))
