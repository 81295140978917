import { BrandIcon, BrandIconContainer, BrandName, BrandsGridContainer, BrandsGridWrapper } from './BrandsGrid.style'
import { EL, PRADA_LINEA_ROSSA, SUN, TP } from '../../constants/ui'
import React, { useEffect, useMemo } from 'react'
import {
    resetELBrands,
    resetOtherBrands,
    setCurrentBrand,
    setELBrands,
    setOtherBrands,
    setTotalBrandsCount,
    setTotalELBrandsCount,
    setTotalOtherBrandsCount,
    toggleBrandSelection
} from '../../redux/slices/BrandsSlice'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../redux/store'
import { Brand } from '../../types/brands'
import { LoaderContainer } from './BrandsGrid.style'
import StyledLoader from '../UI/Loader/Loader'
import { useGetBrandsWithLogo } from '../../api/BrandsApi'
import { setSwitchSelection } from '../../redux/slices/ClusterSlice'

const BrandsGrid = () => {
    const { data: brands, isLoading, error } = useGetBrandsWithLogo()
    const dispatch = useDispatch()
    const selectedBrands = useSelector((state: AppState) => state.brands.selectedBrands)

    const elBrands = useMemo(() => {
        return brands?.filter(({ group }) => group === EL) || []
    }, [brands])
    const otherBrands = useMemo(() => {
        return brands?.filter(({ group }) => group === TP) || []
    }, [brands])

    const selectedBrandType = useSelector((state: AppState) => state.brands.selectedBrandType)
    const selectedBrandCategory = useSelector((state: AppState) => state.brands.selectedBrandCategory)

    const displayedBrands = selectedBrandType === EL ? elBrands : otherBrands
    const filteredBrands = displayedBrands.filter(brand => brand.category.includes(selectedBrandCategory))

    useEffect(() => {
        if (brands?.length) {
            dispatch(setTotalBrandsCount(brands?.length))
            dispatch(setELBrands(elBrands || []))
            dispatch(setOtherBrands(otherBrands || []))
            dispatch(setCurrentBrand(null))
        }
    }, [brands, dispatch, elBrands, otherBrands])

    useEffect(() => {
        dispatch(setSwitchSelection(SUN))
    }, [dispatch])

    if (isLoading) return (
        <LoaderContainer>
            <StyledLoader size={120} />
        </LoaderContainer>
    )

    if (error) {
        console.log(error)
    }

    const handleBrandSelection = (brand: Brand) => {
        if (selectedBrandType === EL) {
            dispatch(resetOtherBrands())
        }
        if (selectedBrandType === TP) {
            dispatch(resetELBrands())
        }

        dispatch(toggleBrandSelection(brand))

        if (brand.group === EL) {
            dispatch(setTotalELBrandsCount(elBrands.length))
        } else {
            dispatch(setTotalOtherBrandsCount(otherBrands.length))
        }
    }

    return (
        <BrandsGridWrapper>
            <BrandsGridContainer>
                {!displayedBrands.length ?
                    <LoaderContainer>
                        <StyledLoader size={120} />
                    </LoaderContainer> :
                    ((filteredBrands.length > 0 ? filteredBrands : displayedBrands)?.map((brand) => {
                        const isPradaLineaRossa = brand.brandName === PRADA_LINEA_ROSSA
                        return (
                            <BrandIconContainer
                                key={brand.brandCode}
                                onClick={() => handleBrandSelection(brand)}
                                isSelected={selectedBrands.some(({ brandCode }) => brandCode === brand.brandCode)}
                            >
                                {brand.urlBrandLogo ?
                                    <BrandIcon isPradaLineaRossa={isPradaLineaRossa} src={brand.urlBrandLogo} /> :
                                    <BrandName>{brand.brandName}</BrandName>}
                            </BrandIconContainer>
                        )
                    }))
                }
            </BrandsGridContainer>
        </BrandsGridWrapper>
    )
}

export default BrandsGrid
