import { Types } from './label'
import { User } from '../types/user'
import moment from 'moment'
import { n2 } from './numbers'

export const getValueFromTagType = (tagType: Types): number => {
  switch (tagType) {
    case Types.ZPFN:
      return 0
    case Types.AP:
      return 1
    default:
      return n2
  }
}

export const getTagTypeFromValue = (value: number): Types => {
  switch (value) {
    case 0:
      return Types.ZPFN
    case 1:
      return Types.AP
    default:
      return Types.ZAFA
  }
}

export const formattedDate = (date: Date | string, format?: string) => {
  if (date) {
    const Z = 'Z'
    const FORMAT = 'L LTS'
    const stringDate = date.toString()
    const isZIncluded = stringDate.includes(Z)

    if (isZIncluded) {
      return moment(date).format(format || FORMAT)
    }
    return moment(stringDate.concat(Z)).format(format || FORMAT)
  }
  return date
}

export const parseJwt = (token: string | null) => {
  if (token) {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join(''),
    )

    return JSON.parse(jsonPayload || '{}')
  }
}

export const useGetUser = (token = '') => {
  const parseToken =
    token === '' ? window.sessionStorage.getItem('token') || null : token
  const jwtInfo = parseJwt(parseToken)

  if (jwtInfo?.signInName) {
    return {
      signInName: jwtInfo.signInName || '',
      email: jwtInfo.emails[0] || '',
      name: jwtInfo.name || '',
      token: parseToken,
    }
  } else {
    return null
  }
}
