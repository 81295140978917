import React, {ReactNode} from 'react'
import {BadgeWrapper} from './Badge.style'

interface BadgeProps {
    children: ReactNode;
}

const Badge: React.FC<BadgeProps> = ({children}) => {
    return (
        <BadgeWrapper>
            {children}
        </BadgeWrapper>
    )
}

export default Badge