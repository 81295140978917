import { styled } from 'styled-components'
import { fluidSize } from '../../../utils/style'

export const Wrapper = styled.div`
  background-color: ${(props) => props.theme.palette.color.white.main};
  padding: ${(props) => props.theme.spacing(4)};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  cursor: pointer;
`

export const ProductInfo = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  align-items: flex-start;

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`

export const SkuCode = styled.span`
  font-size: ${fluidSize(13)};
`

export const Price = styled.span`
  font-size: ${fluidSize(12)};

  &.bolder {
    font-weight: bold;
  }
`

export const ProductImage = styled.div`

  img {
    width: 100%;
    height: auto;
  }
`

export const ProductTagWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: flex-end;
`

export const ProductTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fluidSize(12)};
  line-height: ${fluidSize(12)};
  padding: ${fluidSize(6)} ${fluidSize(8)};
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  &.placeholder {
    height: ${fluidSize(14)};
    border: none;
  }
`
