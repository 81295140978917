import {n16} from '../../../utils/numbers'
import {pxToRem} from '../../../utils/style'
import styled from 'styled-components'

export const BadgeWrapper = styled.div`
  background-color: ${props => props.theme.palette.color.grey.ultralight};
  color: ${props => props.theme.palette.color.black.main};
  border: 1px solid ${props => props.theme.palette.color.grey.light};
  padding: ${props => props.theme.spacing(2.5, 4, 2)};;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: ${pxToRem(n16)};
  font-weight: 700;
`
