import styled from 'styled-components'
import { fluidSize } from '../../utils/style'

const MARGIN_VALUE = 52
const MARGIN = fluidSize(MARGIN_VALUE)
export const MAIN_COLOR = '#356cf8'

export const Wrapper = styled.div`
  width: 100vw;
  margin-left: ${MARGIN};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: ${fluidSize(29, 23, 1366, 1920)};
`

export const PlanogramButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - ${MARGIN});
`

export const CloseIconWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: ${fluidSize(36)};
  height: ${fluidSize(36)};
  background-color: transparent;
  border: ${fluidSize(2)} solid ${MAIN_COLOR};
  cursor: pointer;

  svg {
    height: 35%;
    width: 35%;
    fill: white;
  }
`

export const PlanogramCardsWrapper = styled.div`
  height: 77vh;
  width: 100%;
  overflow-x: auto;
  display: flex;
  column-gap: ${fluidSize(16)};
  padding-right: ${fluidSize(24)};
  box-sizing: border-box;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 0; //hide scrollbar
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${MAIN_COLOR};
    border-radius: 16px;
  }
`

export const PlanogramDownload = styled.div`
  border-radius: 10px;
  font-size: ${fluidSize(16)};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: ${fluidSize(8)};
  color: white;
  font-weight: 600;
  font-family: Gilroy, sans-serif;
  padding: ${fluidSize(12)} ${fluidSize(16)};
  background-color: ${MAIN_COLOR};
  cursor: pointer;
  margin-bottom: ${fluidSize(24)};

  div {
    width: ${fluidSize(16)};
    height: ${fluidSize(16)};
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 100%;
      width: 100%;
      fill: white;
    }
  }
`

export const EmptyCard = styled.div`
  border-radius: 16px;
  background-color: rgb(24, 39, 69);
  height: 100%;
  width: calc(100% - ${fluidSize(MARGIN_VALUE / 2)});
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${fluidSize(20)};
  font-weight: 700;
`
