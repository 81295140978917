export const dragScroll = (
  elem: HTMLElement,
  horizontalScroll: boolean = true,
  verticalScroll: boolean = true
) => {
  let isDrag: boolean = false

  const dragStart = () => {
    elem.setAttribute('style', 'cursor: grabbing;')
    isDrag = true
  }

  const dragEnd = () => {
    elem.setAttribute('style', 'cursor: inherit;')
    isDrag = false
  }

  const drag = (ev: PointerEvent) => {
    if (isDrag) {
      if (horizontalScroll) {
        elem.scrollLeft -= ev.movementX
      }
      if (verticalScroll) {
        elem.scrollTop -= ev.movementY
      }
    }
  }

  elem.addEventListener('pointerdown', dragStart)
  window.addEventListener('pointerup', dragEnd)
  window.addEventListener('pointermove', drag)

  return () => {
    elem.removeEventListener('pointerdown', dragStart)
    window.removeEventListener('pointerup', dragEnd)
    window.removeEventListener('pointermove', drag)
  }
}
