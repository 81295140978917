import { CMSBrand, CMSBrandLogo, CMSBrandTaxonomy } from '../types/cms';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const CmsApi = createApi({
    reducerPath: 'CmsApi',
    baseQuery: fetchBaseQuery({
        baseUrl:
            `${process.env.REACT_APP_API_CMS_URL}` || '',
    }),
    endpoints: build => ({
        getBrandsLogo: build.query<CMSBrandLogo[], void>({
            query: () => '/getContent/CMDownload?additionalParams=logo,third-party-logo',
            transformResponse: (response: CMSBrand[]): CMSBrandLogo[] => {
                if (response.length === 0) return [];
                
                const brlogos = response.map( (item: CMSBrand) => ({
                    code: item.subjectTaxonomy.filter( ({value}: CMSBrandTaxonomy) => (value !== "Logo") )[0].value || '-1',
                    urlBrandLogo: item.path
                }))
                return brlogos
              }
        }),
    }),
})

export const { useGetBrandsLogoQuery } = CmsApi
