import './App.css'

import { StyledEngineProvider, ThemeProvider as ThemeProviderMUI } from '@mui/material'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import BaseLayout from './layouts/BaseLayout'
import { CreateRouter } from './app/routers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import NavigationBar from './components/NavigationBar/NavigationBar'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import i18n from 'i18next'
import moment from 'moment'
import theme from './themes/RCAssortment/theme'

const App: React.FC = () => {
    moment.locale(i18n.language?.split('-')[0])
    
    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            adapterLocale={i18n.language}
        >
            <StyledEngineProvider injectFirst>
                <ThemeProviderMUI theme={theme}>
                    <ThemeProvider theme={theme}>
                        <BaseLayout>
                            <CreateRouter />
                            <NavigationBar />
                        </BaseLayout>
                    </ThemeProvider>
                </ThemeProviderMUI>
            </StyledEngineProvider>
        </LocalizationProvider>
    )
}

export default App
