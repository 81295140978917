import styled from 'styled-components'
import { fluidSize, pxToRem } from '../../utils/style'
import { n32 } from '../../utils/numbers'

export const AppHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${(props) => props.theme.palette.color.white.main};
  margin: ${(props) => props.theme.spacing(3, 8.25)};
`

export const AppFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AppTitle = styled.h1`
  display: flex;
  font-size: ${pxToRem(n32)};
  color: ${(props) => props.theme.palette.color.white.main};
`

export const ClusterTitle = styled.h2`
  color: ${(props) => props.theme.palette.color.white.main};
  font-size: ${fluidSize(18)};
  font-weight: bold;
  margin: ${fluidSize(28)} 0;
`
