import styled from 'styled-components'
import { fluidSize } from '../../utils/style'

export const Wrapper = styled.div`
  background-color: white;
  border-radius: 16px;
  width: ${fluidSize(636, 877, 1366, 1920)};
  height: 85vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Gilroy, sans-serif;
  color: black;
`

export const ImagesWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(auto, max-content);
  gap: ${fluidSize(16, 20, 1366, 1920)};
  padding: 0 ${fluidSize(50, 59, 1366, 1920)};

  &::-webkit-scrollbar {
    width: 0;
  }

  @media (min-width: 1367px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const LogoWrapper = styled.div`
  width: 25%;
  height: auto;
  min-height: ${fluidSize(85)};
  margin: ${fluidSize(24)} auto ${fluidSize(12)};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`

export const ImageWrapper = styled.div`
  img {
    width: 100%;
    height: auto;
  }
`

export const InfoWrapper = styled.div`
  box-shadow: 0 -20px 15px rgba(255, 255, 255, 0.5);
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: ${fluidSize(1, 2)} solid rgba(0, 0, 0, 0.1);
  padding: ${fluidSize(16)} ${fluidSize(32)} ${fluidSize(24)};
`

export const ClusterName = styled.span`
  font-size: ${fluidSize(18)};
  font-weight: 700;
`
