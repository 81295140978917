import { BrandCluster, BrandClusterPayload, Cluster, ClusterMutationPayload, ClusterReview, ClusterReviewPayload, QueryResult } from '../types/clusters'

import { Brand } from '../types/brands'
import { CMSBrandLogo } from '../types/cms'
import {createApi} from '@reduxjs/toolkit/query/react'
import { parseJwt } from '../utils/utils'
import {staggeredBaseQuery} from '../utils/query'
import { useGetBrandsLogoQuery } from './CmsApi'

const BRANDS_TAG = 'Brands'
const CLUSTERS_TAG = 'Clusters'
export const REVIEW_TAG = 'Review'

export const BrandsApi = createApi({
    reducerPath: 'brandsApi',
    baseQuery: staggeredBaseQuery(
        `${process.env.REACT_APP_API_BASE_URL}` ||
        'http://localhost:3001',
    ),
    tagTypes: [BRANDS_TAG, CLUSTERS_TAG, REVIEW_TAG],
    endpoints: build => ({
        getBrands: build.query<Brand[], void>({
            query: () => ({
                url: `/brand`,
            }),
            providesTags: [BRANDS_TAG],
        }),
        getBrandDetails: build.query<Cluster[], string | undefined>({
            query: (brandCode) => `/brand/${brandCode}/cluster`,
            transformResponse: (response: BrandCluster[], _, brandCode): Cluster[] => {
                if (response.length === 0) return [];
                
                // TODO: da eliminare ASSOLUTAMENTE dopo l'online di wave 1 - verrà sostituito dalla dropdown di selezione mese
                const brlogos = response.filter( ({month}: BrandCluster) => (month === 'M04_2024')).map( (item) => ({
                    ...item, 
                    brandCode
                }))

                return brlogos
            },
            providesTags: [BRANDS_TAG],
        }),
        getClustersReview: build.query<Cluster[], ClusterReviewPayload>({
            query: ({signInName, email}: ClusterReviewPayload) => ({url: `/review?signInName=${signInName}&email=${email}`}),
            transformResponse: (response: ClusterReview[]) => {
                if (response.length === 0) return []
                return response.reduce( (acc: Cluster[], item) => {
                    
                    item.clusters.forEach((o) => {
                      acc.push({
                          ...o,
                          brandCode: item.brandCode,
                        })
                    })

                    return acc
                }, [])
            },
            providesTags: [CLUSTERS_TAG, REVIEW_TAG]
        }),
        putCluster: build.mutation<
            string,
            { brandCode: string, clusterCode: string, clusterMonth: string }
        >({
            queryFn: async ({ brandCode, clusterCode, clusterMonth }, _, __, baseQuery) => {
                const token = window.sessionStorage.getItem('token') || ''
                const jwtInfo = parseJwt(token)

                const body: ClusterMutationPayload = {
                    email: jwtInfo.emails[0],
                    signInName: jwtInfo.signInName,
                    month: clusterMonth
                }

                const result = (await baseQuery({
                  url: `/brand/${brandCode}/cluster/${clusterCode}`,
                  method: 'PUT',
                  body,
                })) as QueryResult<string>

                if (result.error) {
                  return {data: ''}
                }
                return { data: result.data }
            },
            invalidatesTags: () => [CLUSTERS_TAG],
        }),
        deleteCluster: build.mutation<
            string,
            { brandCode: string, clusterCode: string, clusterMonth: string }
        >({
            queryFn: async ({ brandCode, clusterCode, clusterMonth }, _, __, baseQuery) => {
                const token = window.sessionStorage.getItem('token') || ''
                const jwtInfo = parseJwt(token)

                const body: ClusterMutationPayload = {
                    email: jwtInfo.emails[0],
                    signInName: jwtInfo.signInName,
                    month: clusterMonth
                }

                const result = (await baseQuery({
                  url: `/brand/${brandCode}/cluster/${clusterCode}`,
                  method: 'DELETE',
                  body,
                })) as QueryResult<string>

                if (result.error) {
                  return {data: ''}
                }
                return { data: result.data }
            },
            invalidatesTags: () => [CLUSTERS_TAG],
        }),
    }),
})

export const useGetClusterUnique = (cluster: BrandClusterPayload) => {
    const {brandCode, code, month} = cluster
    const { data: clusters, isLoading, error } = BrandsApi.useGetBrandDetailsQuery(brandCode, { skip: !brandCode })

    const current = clusters?.find( (cl) => (cl.code === code && cl.month === month) )

    return {
        isLoading,
        data: current,
        error
    }
}

export const useGetBrandsWithLogo = () => {
    const brandsLogo = useGetBrandsLogoQuery()
    const brands = BrandsApi.useGetBrandsQuery(undefined, {
        skip: brandsLogo.isLoading
    })
    
    let matchBrands: Brand[] | undefined = []

    if(brandsLogo.isSuccess) {
        matchBrands = brands.data?.reduce( (acc: Brand[], item, index) => {
            acc[index] = {
                ...item, 
                urlBrandLogo: (brandsLogo.data?.find( ({code}: CMSBrandLogo) => code === item.brandCode)) 
                    ? (brandsLogo?.data.find( ({code}: CMSBrandLogo) => code === item?.brandCode))?.urlBrandLogo || '' : null
            }
            return acc
        }, [])
    } else {
        matchBrands = brands.data?.map( (brand) => ({
            ...brand, 
            urlBrandLogo: null
        }))        
    }

    return {
        isLoading: brandsLogo.isLoading && brands.isLoading,
        data: matchBrands,
        error: brandsLogo.error || brands.error
    }
}

export const useGetBrandDetailsQuery = (brandCode: string) => {
    const {data: cluster, isLoading, error} = BrandsApi.useGetBrandDetailsQuery(brandCode, {skip: !brandCode})

    return {
        isLoading,
        error,
        data: cluster
    }
}

export const useGetClustersReviewQuery = (userInfo: ClusterReviewPayload) => {
    const reviewclusters = BrandsApi.useGetClustersReviewQuery(userInfo, {skip: !userInfo})
    
    return {
        ...reviewclusters
    }
}

export const {
    useGetBrandsQuery,
    usePutClusterMutation,
    useDeleteClusterMutation,
} = BrandsApi

