import React from 'react'
import {BackArrowContainer, StyledBackArrowIcon} from './BackArrow.style'
import {useNavigate} from 'react-router-dom'

interface BackCTAProps {
    destination: string | number
}

const BackCTA: React.FC<BackCTAProps> = ({destination}) => {
    const navigate = useNavigate()

    const handleClick = () => {
        if (typeof destination === 'number') {
            navigate(destination)
        } else {
            navigate(destination)
        }
    }


    return (
        <BackArrowContainer>
            <StyledBackArrowIcon onClick={handleClick} />
        </BackArrowContainer>
    )
}

export default BackCTA