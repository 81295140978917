import styled from 'styled-components';

export const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  gap: 12px;

  &:hover,
  &:focus {
    background: rgba(0, 0, 0, 0.1);
  }
`;
