import React, { useEffect, useState } from 'react'
import {
  ArrowContainer,
  BrandCategorySelector,
  BrandCategorySelectorRow,
  BrandSelectCTAContainer,
  OtherBrandsCategory,
  SelectionRowContainer,
  StyledCategorySpan,
  StyledExploreSpan,
} from './SelectionRow.style'
import SelectCheckbox from '../UI/SelectCheckbox/SelectCheckbox'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import {
  resetSelectedBrands,
  selectAllBrands,
  setDisplayedBrands,
  setSelectedBrandCategory,
} from '../../redux/slices/BrandsSlice'
import { useTranslation } from 'react-i18next'
import DownArrow from '../UI/Arrows/DownArrow/DownArrow'
import UpArrow from '../UI/Arrows/UpArrow/UpArrow'
import {
  ALL_BRANDS,
  EL,
  FAST_FASHION,
  LIFESTYLE,
  LOCAL_COLLECTION,
  LUXURY,
  PREMIUM_FASHION,
  SPORT_PERFORMANCE,
} from '../../constants/ui'
import Dialog from '../UI/Dialog/Dialog'
import RadioForm from '../UI/RadioForm/RadioForm'

const SelectionRow = () => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const closeDialog = () => setIsOpen(false)

  const brandCategories = [
    {
      value: ALL_BRANDS,
      label: t('brandCategory.allBrandsCapital'),
    },
    {
      value: LIFESTYLE,
      label: `${LIFESTYLE} ${t('brandCategory.brands')}`,
    },
    {
      value: LUXURY,
      label: `${LUXURY} ${t('brandCategory.brands')}`,
    },
    {
      value: PREMIUM_FASHION,
      label: `${PREMIUM_FASHION} ${t('brandCategory.brands')}`,
    },
    {
      value: FAST_FASHION,
      label: `${FAST_FASHION} ${t('brandCategory.brands')}`,
    },
    {
      value: SPORT_PERFORMANCE,
      label: `${SPORT_PERFORMANCE} ${t('brandCategory.brands')}`,
    },
    {
      value: LOCAL_COLLECTION,
      label: `${LOCAL_COLLECTION} ${t('brandCategory.brands')}`,
    },
  ]

  const dispatch = useDispatch()
  const selectedBrands = useSelector(
    (state: AppState) => state.brands.selectedBrands,
  )
  const elBrands = useSelector((state: AppState) => state.brands.elBrands)
  const otherBrands = useSelector((state: AppState) => state.brands.otherBrands)
  const selectedBrandType = useSelector(
    (state: AppState) => state.brands.selectedBrandType,
  )
  const selectedBrandCategory = useSelector(
    (state: AppState) => state.brands.selectedBrandCategory,
  )
  const isSelectedBrandCategory = selectedBrandCategory !== ALL_BRANDS

  const displayedBrands = (
    selectedBrandType === EL ? elBrands : otherBrands
  ).filter((brand) => {
    return (
      selectedBrandCategory === ALL_BRANDS ||
      brand.category.includes(selectedBrandCategory)
    )
  })
  const isAllSelected =
    displayedBrands.length > 0 &&
    displayedBrands.length === selectedBrands.length

  const selectedDisplayedBrandsCount = displayedBrands.filter(
    (displayedBrand) =>
      selectedBrands.some(
        (selectedBrand) => selectedBrand.brandCode === displayedBrand.brandCode,
      ),
  ).length
  const handleChangeCategory = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedBrandCategory(e.target.value))
    closeDialog()
  }

  const handleCheckboxChange = () => {
    if (isAllSelected) {
      dispatch(resetSelectedBrands())
    } else {
      dispatch(selectAllBrands(displayedBrands))
    }
  }

  useEffect(() => {
    dispatch(setDisplayedBrands(displayedBrands))
  }, [dispatch, displayedBrands])

  return (
    <SelectionRowContainer>
      <BrandCategorySelector>
        <BrandCategorySelectorRow>
          <StyledExploreSpan>
            {t('home.explore')}
            {selectedBrandType === 'el' ? (
              <StyledCategorySpan
                isSelectedBrandCategory={isSelectedBrandCategory}
              >
                {selectedBrandCategory === ALL_BRANDS
                  ? t('brandCategory.allBrands')
                  : `${selectedBrandCategory} ${t('brandCategory.brands')}`}
              </StyledCategorySpan>
            ) : (
              <OtherBrandsCategory>
                {t('brandCategory.brands')}
              </OtherBrandsCategory>
            )}
          </StyledExploreSpan>

          {selectedBrandType === 'el' && (
            <ArrowContainer
              onClick={() => setIsOpen(!isOpen)}
              isSelectedBrandCategory={isSelectedBrandCategory}
            >
              {isOpen ? (
                <UpArrow sx={{ width: 30, height: 30 }} />
              ) : (
                <DownArrow sx={{ width: 30, height: 30 }} />
              )}
            </ArrowContainer>
          )}
        </BrandCategorySelectorRow>
        <Dialog top={64} left={8} isOpen={isOpen} onClose={closeDialog}>
          <RadioForm
            options={brandCategories}
            name="category"
            selectedValue={selectedBrandCategory}
            onChange={handleChangeCategory}
          />
        </Dialog>
      </BrandCategorySelector>
      <BrandSelectCTAContainer>
        <SelectCheckbox
          checked={isAllSelected}
          onClick={handleCheckboxChange}
          sx={{
            color: '#ffffff',
            '&.Mui-checked': {
              color: '#7a7a7a',
            },
          }}
        />{' '}
        {t('home.selectAll')} ({selectedDisplayedBrandsCount})
      </BrandSelectCTAContainer>
    </SelectionRowContainer>
  )
}

export default SelectionRow
