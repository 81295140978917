import styled from 'styled-components';
import {pxToRem} from '../../../utils/style'
import {n10, n24, n9} from '../../../utils/numbers'

interface StyledLabelProps {
    checked: boolean;
}

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  gap: 12px;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  display: inline-flex;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: ${pxToRem(n24)};
    height: ${pxToRem(n24)};
    border: 2px solid ${props => props.checked ? props.theme.palette.primary.light : props.theme.palette.color.grey.light};
    background-color: ${props => props.checked ? props.theme.palette.primary.light : props.theme.palette.color.grey.light};
    border-radius: 50%;
    margin-right: ${props => props.theme.spacing(3)};
    cursor: pointer;
  }

  &::after {
    content: ${props => props.checked ? '""' : 'none'};
    display: block;
    position: absolute;
    width: ${pxToRem(n10)};
    height: ${pxToRem(n10)};
    background-color: ${props => props.theme.palette.color.white.main};
    border-radius: 50%;
    left: ${pxToRem(n9)};
    top: ${pxToRem(n9)};
  }
`;

export const StyledInput = styled.input`
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
`;