import {
  CLUSTER_CARD_HEIGHT,
  CLUSTER_CARD_WIDTH,
  CLUSTER_IMAGE_CONTAINER_HEIGHT,
  CLUSTER_PLP_CONTAINER_HEIGHT,
  Z_INDEX_LV1,
  Z_INDEX_LV2,
} from '../../constants/ui'
import { n14, n16, n18, n20, n32 } from '../../utils/numbers'

import Button from '../UI/Buttons/Button/Button'
import { SunglassesIcon } from '../UI/Icons/Icons'
import { hexToRgbA, pxToRem } from '../../utils/style'
import styled from 'styled-components'
import { MAIN_COLOR } from '../Planogram/Planogram.style'

export const ClusterCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${(props) => props.theme.palette.primary.dark};
  color: ${(props) => props.theme.palette.color.black.main};
  width: ${CLUSTER_CARD_WIDTH}px;
  height: ${CLUSTER_CARD_HEIGHT}px;
  min-width: ${CLUSTER_CARD_WIDTH}px;
  position: relative;
  overflow: hidden;
  user-select: none;
`

export const ClusterImageContainer = styled.div`
  color: ${(props) => props.theme.palette.color.white.main};
  position: relative;
  height: ${CLUSTER_IMAGE_CONTAINER_HEIGHT}px;
  overflow: hidden;
`

export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: ${(props) => props.theme.palette.color.backgrounds.overlay};
  border-radius: 12px 12px 0 0;
  z-index: ${Z_INDEX_LV1};
`

export const ClusterCardPLPContainer = styled.div`
  background-color: ${(props) => props.theme.palette.color.white.main};
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: ${(props) => props.theme.spacing(69)};
  height: ${CLUSTER_PLP_CONTAINER_HEIGHT}px;
  border-radius: 12px;
  margin: ${(props) => props.theme.spacing(0, 2)};
  z-index: ${Z_INDEX_LV2};
  width: ${pxToRem(396)};
`

export const CardHeaderImage = styled.img`
  object-fit: cover;
  border-radius: 12px 12px 0 0;
`

export const ClusterCodeContainer = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  bottom: ${(props) => props.theme.spacing(35)};
  z-index: ${Z_INDEX_LV2};
`

export const ClusterCodeIconContainer = styled.div`
  width: ${pxToRem(n20)};
  height: ${pxToRem(n20)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(3.5, 3, 3, 3)};
  color: ${(props) => props.theme.palette.primary.dark};
  background-color: ${(props) => props.theme.palette.color.white.main};
  border-radius: 50%;
  margin-right: ${pxToRem(n32)};
  cursor: pointer;
`

export const ClusterCode = styled.span`
  font-size: ${pxToRem(n18)};
  font-weight: 700;
  margin-left: ${pxToRem(n32)};
`

export const ClusterCardDescription = styled.p`
  font-size: ${pxToRem(n14)};
  font-weight: lighter;
  padding: ${(props) => props.theme.spacing(0, 4)};
`

export const ProductImagesGridContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  padding: ${(props) => props.theme.spacing(0, 4)};
`

export const ProductImagesGrid = styled('div').withConfig({
  shouldForwardProp: (prop) =>
    prop !== 'isShortList' && prop !== 'productListColumns',
})<{ isShortList?: boolean; productListColumns?: number }>(
  ({ isShortList, productListColumns, theme }) => ({
    display: isShortList ? 'flex' : 'grid',
    height: 190,
    flexWrap: isShortList ? 'wrap' : 'nowrap',
    gridTemplateColumns: isShortList
      ? 'none'
      : `repeat(${productListColumns}, minmax(110px, 1fr))`,
    gridAutoRows: isShortList ? 'none' : 'minmax(3, 5px)',
    gap: isShortList ? 0 : theme.spacing(1, 4),
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      height: '5px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },

    '@media (hover: hover)': {
      '&:hover': {
        '&::-webkit-scrollbar': {
          backgroundColor: hexToRgbA(MAIN_COLOR, '0.1'),
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: MAIN_COLOR,
        },
      },
    },

    '@media (hover: none)': {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  }),
)

export const ProductImageContainer = styled.div`
  flex: 0 0 auto;
`

export const ProductImageIcon = styled.img`
  width: ${pxToRem(105)};
  height: ${pxToRem(53)};
  padding: ${pxToRem(2)};
  -webkit-user-drag: none;
`

export const BadgeContainer = styled.div`
  position: absolute;
  width: 90%;
  top: ${(props) => props.theme.spacing(4)};
  display: flex;
  justify-content: flex-end;
  padding: ${(props) => props.theme.spacing(0, 5)};
`

export const GlassesIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
`

export const SmallSunglassesIcon = styled(SunglassesIcon)`
  width: ${pxToRem(n16)};
  height: ${pxToRem(n16)};
`

export const PlpButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: ${(props) => props.theme.spacing(2)};
  padding-bottom: ${(props) => props.theme.spacing(5)};
`

export const StyledButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<{ isSelected?: boolean }>(({ isSelected, theme }) => ({
  width: 170,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: isSelected ? theme.palette.secondary.main : 'transparent',
  textTransform: 'uppercase',
  borderRadius: 8,
  padding: theme.spacing(4, 12),
  fontWeight: 'bolder',
  border: `2px solid ${theme.palette.primary.dark}`,
  ':focus': {
    backgroundColor: theme.palette.secondary.main,
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export const CheckedIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${(props) => props.theme.spacing(5)};
`

export const ProductNoImagePlaceholder = styled('span')(() => ({
  minWidth: 100,
  display: 'flex',
  alignItems: 'center',
  padding: 4,
}))
