import './index.css'

import React, {Suspense} from 'react'

import App from './App'
import {I18nextProvider} from 'react-i18next'
import Loader from './components/UI/Loader/Loader'
import {Provider} from 'react-redux'
import ReactDOM from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'
import i18n from './i18n'
import reportWebVitals from './reportWebVitals'
import store from './redux/store'

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <Suspense fallback={<Loader />}>
                    <Router>
                        <App/>
                    </Router>
                </Suspense>
            </I18nextProvider>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
