import { createSlice } from '@reduxjs/toolkit'

type StateInterface = {
    products: []
}

const initialState: StateInterface = {
    products: [],
}

export const ProductsSlice = createSlice({
    name: 'products',
    initialState,

    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload
        },
        resetProducts: state => {
            state.products = []
        },
    },
})

export const { setProducts, resetProducts } = ProductsSlice.actions

export default ProductsSlice.reducer
