import {
  ConfirmCTA,
  ConfirmSelectionContainer,
} from './ConfirmSelectionCTA.style'
import { useNavigate } from 'react-router-dom'

import { AppState } from '../../redux/store'
import { CLUSTERS } from '../../constants/routes'
import RightArrow from '../UI/Arrows/RightArrow/RightArrow'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { setResetCompare } from '../../redux/slices/ClusterSlice'
import { selectAllBrands } from '../../redux/slices/BrandsSlice'
import { BrandsApi, REVIEW_TAG } from '../../api/BrandsApi'
import { setDownloadWasClicked } from '../../redux/slices/GenericSlice'

const ConfirmSelectionCTA = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const downloadWasClicked = useSelector(
    (state: AppState) => state.generic.downloadWasClicked,
  )
  const selectedBrands = useSelector(
    (state: AppState) => state.brands.selectedBrands,
  )
  const displayedBrands = useSelector(
    (state: AppState) => state.brands.displayedBrands,
  )
  const selectedBrandsType = useSelector(
    (state: AppState) => state.brands.selectedBrandType,
  )
  const compareClusters = useSelector(
    (state: AppState) => state.cluster.compare,
  )
  const resetCompare =
    !!compareClusters.length &&
    compareClusters[0].brand.group.toLowerCase() !==
      selectedBrandsType.toLowerCase()

  const displayedBrandsCodes = displayedBrands.map(({ brandCode }) => brandCode)

  const brandsToForward = selectedBrands.filter(({ brandCode }) =>
    displayedBrandsCodes.includes(brandCode),
  )

  const disabled = brandsToForward.length === 0

  return (
    <ConfirmSelectionContainer>
      <ConfirmCTA
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            if (downloadWasClicked) {
              dispatch(BrandsApi.util.invalidateTags([REVIEW_TAG]))
              setDownloadWasClicked(false)
            }

            dispatch(selectAllBrands(brandsToForward))
            resetCompare && dispatch(setResetCompare())
            navigate(CLUSTERS)
          }
        }}
      >
        {t('home.confirmSelection')}
        <RightArrow />
      </ConfirmCTA>
    </ConfirmSelectionContainer>
  )
}

export default ConfirmSelectionCTA
