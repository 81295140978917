import { CloseIcon, DownloadIcon } from '../UI/Icons/Icons'
import {
  CloseIconWrapper,
  EmptyCard,
  PlanogramButtonsWrapper,
  PlanogramCardsWrapper,
  PlanogramDownload,
  Wrapper,
} from './Planogram.style'
import { useDispatch, useSelector } from 'react-redux'

import { AppState } from '../../redux/store'
import { HOME } from '../../constants/routes'
import PlanogramCard from './PlanogramCard/PlanogramCard'
import PlanogramSwitch from './PlanogramSwitch/PlanogramSwitch'
import { Product } from '../../types/products'
import { Type } from '../../types/clusters'
import { downloadPlanogram } from '../../utils/query'
import { fluidSizeNumber } from '../../utils/style'
import {
  resetCompareClusters,
  resetSelectedClusters,
} from '../../redux/slices/ClusterSlice'
import { useDragScroll } from '../../hooks/useDragScroll'
import { useGetBrandsWithLogo } from '../../api/BrandsApi'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  resetSelectedBrands,
  setSelectedBrandCategory,
  setSelectedBrandType,
} from '../../redux/slices/BrandsSlice'
import { ALL_BRANDS } from '../../constants/ui'
import { setDownloadWasClicked } from '../../redux/slices/GenericSlice'

export type PlanogramType = {
  brandName: string
  urlBrandLogo: string | null
  opticalProducts: Product[]
  sunProducts: Product[]
}

type PlanogramCardType = {
  [brandCode: string]: PlanogramType
}

const PLANOGRAM_ID = 'planogram-cards-wrapper'

type Props = {
  onClose: () => void
}

const Planogram: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation()
  const { data: brands } = useGetBrandsWithLogo()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectedCluster = useSelector(
    (state: AppState) => state.cluster.selected,
  )

  const [selectedType, setSelectedType] = useState<Type | null>(null)

  const planogramCards = selectedCluster.reduce((sum, cluster) => {
    const { code, type, products } = cluster
    const clusterBrandCode = cluster.brandCode
      ? cluster.brandCode.substring(0, 2).toUpperCase()
      : code.substring(0, 2).toUpperCase()

    if (clusterBrandCode in sum) {
      const previousOpt = [...sum[clusterBrandCode].opticalProducts]
      const previousSun = [...sum[clusterBrandCode].sunProducts]

      sum[clusterBrandCode] = {
        ...sum[clusterBrandCode],
        opticalProducts:
          type === 'opt' ? [...previousOpt, ...products] : [...previousOpt],
        sunProducts:
          type === 'sun' ? [...previousSun, ...products] : previousSun,
      }
    } else {
      const brand = brands?.find(
        ({ brandCode }) => brandCode.toUpperCase() === clusterBrandCode,
      )

      sum[clusterBrandCode] = {
        brandName: brand?.brandName || '',
        urlBrandLogo: brand?.urlBrandLogo || null,
        opticalProducts: type === 'opt' ? products : [],
        sunProducts: type === 'sun' ? products : [],
      }
    }

    return sum
  }, {} as PlanogramCardType)

  const noCardsToShow = !Object.values(planogramCards).filter((card) => {
    if (selectedType === 'sun') {
      return !!card.sunProducts.length
    }
    if (selectedType === 'opt') {
      return !!card.opticalProducts.length
    }
    return true
  }).length

  const emptyCategory =
    selectedType === 'sun' ? t('productType.sun') : t('productType.opt')

  useDragScroll(PLANOGRAM_ID, 'horizontal')

  const resetApplication = async () => {
    dispatch(setDownloadWasClicked(true))
    dispatch(resetSelectedClusters())
    dispatch(resetSelectedBrands())
    dispatch(setSelectedBrandType('el'))
    dispatch(resetCompareClusters())
    dispatch(setSelectedBrandCategory(ALL_BRANDS))

    return Promise.resolve()
  }

  return (
    <Wrapper>
      <PlanogramButtonsWrapper>
        <PlanogramSwitch
          setSelectedType={setSelectedType}
          selectedType={selectedType}
        />
        <CloseIconWrapper onClick={onClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </PlanogramButtonsWrapper>

      <PlanogramCardsWrapper id={PLANOGRAM_ID}>
        {noCardsToShow ? (
          <EmptyCard>
            {t('planogram.empty', {
              category: selectedType ? emptyCategory : '',
            })}
          </EmptyCard>
        ) : (
          Object.keys(planogramCards).map((brandCode, index) => {
            return (
              <PlanogramCard
                key={`${brandCode}-${index}`}
                selectedType={selectedType}
                planogram={planogramCards[brandCode]}
              />
            )
          })
        )}
      </PlanogramCardsWrapper>

      <PlanogramButtonsWrapper>
        <div /> {/* Filter placeholder */}
        <PlanogramDownload
          onClick={() =>
            downloadPlanogram().then(async (response: boolean) => {
              if (response) {
                await resetApplication()
                navigate(HOME)
              } else {
                //TO DO: Aggiungere logica gestione errore
                console.error('error in download')
              }
            })
          }
        >
          {t('planogram.download')}
          <div>
            <DownloadIcon
              width={fluidSizeNumber(18)}
              height={fluidSizeNumber(18)}
            />
          </div>
        </PlanogramDownload>
      </PlanogramButtonsWrapper>
    </Wrapper>
  )
}

export default Planogram
