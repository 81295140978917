import { useTranslation } from 'react-i18next'
import {
  Compare,
  CompareIconWrapper,
  Counter,
  ChevronIcon,
  Wrapper,
  CompareWarning,
  warningModalStyle,
  CloseIconWrapper,
  compareModalStyle,
  CompareCardsWrapper,
} from './CompareCounter.style'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from '../../redux/store'
import {
  resetCompareClusters,
  setCompareWarning,
} from '../../redux/slices/ClusterSlice'
import Modal from '../Modal/Modal'
import { CloseIcon } from '../UI/Icons/Icons'
import { useState } from 'react'
import CompareCard from '../CompareCard/CompareCard'
import { MODAL_FADE_EFFECT_DURATION } from '../Modal/Modal.style'

export const MAX_CLUSTER_TO_COMPARE = 2

const CompareCounter: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const compareWarning = useSelector(
    (state: AppState) => state.cluster.compareWarning,
  )
  const compareClusters = useSelector(
    (state: AppState) => state.cluster.compare,
  )

  const [showCompare, setShowCompare] = useState(false)

  const active = compareClusters.length === MAX_CLUSTER_TO_COMPARE

  return (
    <Wrapper>
      <Compare>{t('buttons.compare')}</Compare>
      <Counter>
        {compareClusters.length}/{MAX_CLUSTER_TO_COMPARE}
      </Counter>
      <CompareIconWrapper
        onClick={() => active && setShowCompare(true)}
        darker={active}
      >
        <ChevronIcon />
      </CompareIconWrapper>

      <Modal
        onClose={() => dispatch(setCompareWarning(false))}
        visible={compareWarning}
        hideCloseButton
        customStyle={warningModalStyle}
      >
        <CompareWarning onClick={() => dispatch(setCompareWarning(false))}>
          <p>{t('compare.warning')}</p>
          <CloseIconWrapper>
            <CloseIcon />
          </CloseIconWrapper>
        </CompareWarning>
      </Modal>

      <Modal
        onClose={() => {
          setShowCompare(false)
          setTimeout(() => {
            dispatch(resetCompareClusters())
          }, MODAL_FADE_EFFECT_DURATION)
        }}
        visible={showCompare}
        customStyle={compareModalStyle}
      >
        <CompareCardsWrapper>
          {compareClusters.map((brandAndCluster) => {
            return (
              <CompareCard
                key={JSON.stringify(brandAndCluster)}
                brandAndCluster={brandAndCluster}
              />
            )
          })}
        </CompareCardsWrapper>
      </Modal>
    </Wrapper>
  )
}

export default CompareCounter
